import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FormHelperText } from "@mui/material";

export default function InputRadio(props) {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    error = null,
    options,
    ...other
  } = props;

  return (
    <FormControl {...other}>
      <FormLabel id={name}>{label}</FormLabel>
      <RadioGroup aria-labelledby={name} name={name} value={value}>
        {options.map((item, i) => (
          <FormControlLabel
            key={i}
            value={item.id}
            onChange={onChange}
            control={<Radio />}
            label={item.name}
          />
        ))}
      </RadioGroup>
      {error && (
        <FormHelperText sx={{ color: "#d32f2f" }}>{error}</FormHelperText>
      )}
    </FormControl>
  );
}
