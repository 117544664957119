import React from "react";
import { AddRounded, RemoveRounded } from "@mui/icons-material";
import { Button, InputAdornment, TextField } from "@mui/material";

const InputQty = ({ value, onChange, onIncrease, onDecrease, ...other }) => {
  return (
    <TextField
      size="small"
      value={value}
      onChange={onChange}
      type="number"
      className="rmv-arrow"
      sx={{
        "& .MuiInputBase-root": {
          maxWidth: "120px",
          padding: "0px 0px",
        },
        "& .MuiInputBase-input": {
          padding: "5.5px 0px",
          textAlign: "center",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Button
              onClick={onDecrease && onDecrease}
              size="small"
              sx={{
                "&:hover": { background: "none" },
                background: "none",
                borderRadius: 0,
                minWidth: "20px",
                paddingLeft: "3px",
                paddingRight: "3px",
                marginRight: 0,
                borderRight: "1px solid var(--graySM-color)",
              }}
            >
              <RemoveRounded />
            </Button>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Button
              onClick={onIncrease && onIncrease}
              size="small"
              sx={{
                "&:hover": { background: "none" },
                background: "none",
                borderRadius: 0,
                minWidth: "20px",
                marginRight: 0,
                paddingLeft: "3px",
                paddingRight: "3px",
                borderLeft: "1px solid var(--graySM-color)",
              }}
            >
              <AddRounded />
            </Button>
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};

export default InputQty;
