import React, { useState } from "react";
import { Button, Grow, Box, Paper } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function FileInput(props) {
  const {
    name,
    label,
    onChange,
    error = null,
    values,
    rmv,
    accept = "image/*",
    src,
    maxSize = 3,
  } = props;

  const [sizeErr, setSizeErr] = useState();
  const [fileName, setFileName] = useState();

  const handleFile = (e) => {
    let file = e.target.files[0];
    if (e.target.files && e.target.files[0] && file.size / 1048576 < maxSize) {
      onChange({ name, value: file });
      file !== undefined && setFileName(file.name);
      setSizeErr();
    } else setSizeErr(`Maximum Allowed ${maxSize}MB`);
  };
  const rmvImg = () => {
    setFileName();
    rmv !== undefined && rmv();
  };

  return (
    <Paper className="p-5 br-8 gray-color shadow-none bs-secondary">
      <p className="mt-0 mb-3 ml-5 fs-12">
        <span>File Name:&nbsp;&nbsp;</span>
        {fileName ? (
          fileName
        ) : (
          <a href={src} target="_blank" rel="noreferrer">
            {values}
          </a>
        )}
      </p>
      <Box className="d-flex">
        <label htmlFor={name} className="w-100pr">
          <input
            className="d-none"
            accept={accept}
            id={name}
            onChange={handleFile}
            multiple
            type="file"
          />
          <Button
            className="w-100pr"
            startIcon={<DriveFolderUploadIcon />}
            component="span"
            size="small"
          >
            {label}
          </Button>
        </label>
        {(fileName || src) && (
          <Grow in timeout={800}>
            <Button onClick={rmvImg} className="ml-5" size="small">
              <CloseIcon sx={{ fontSize: "20px" }} />
            </Button>
          </Grow>
        )}
      </Box>
      {(error || sizeErr) && (
        <FormHelperText sx={{ color: "#d32f2f" }}>
          {error}
          {sizeErr}
        </FormHelperText>
      )}
    </Paper>
  );
}
