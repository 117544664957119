import { Box, Grid } from "@mui/material";
import React from "react";
import Dialog from "./Dialog";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button } from "../controls";
import { useState } from "react";

const AlertIcon = ({ alert }) => {
  switch (alert.type) {
    case "success":
      return (
        <CheckCircleRoundedIcon color={alert.type} sx={{ fontSize: "70px" }} />
      );
    case "warning":
      return (
        <WarningAmberRoundedIcon color={alert.type} sx={{ fontSize: "70px" }} />
      );
    case "error":
      return (
        <ErrorOutlineRoundedIcon color={alert.type} sx={{ fontSize: "70px" }} />
      );
    case "info":
      return <InfoOutlinedIcon color={alert.type} sx={{ fontSize: "70px" }} />;
    default:
      break;
  }
};

const AlertTextIcon = ({ alert }) => {
  return (
    <>
      <div className="mt-30 mb-10">
        <AlertIcon {...{ alert }} />
      </div>
      <div className="gray-color d-grid pl-15 pr-15">
        <span className="fs-24 mt-0 mb-10 fw-5">{alert.title}</span>
        <span>{alert.subTitle}</span>
      </div>
    </>
  );
};

const ConfirmationCom = (props) => {
  const { alert, setAlert } = props;
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Grid
        container
        direction="column"
        sx={{
          position: "relative",
          textAlign: "center",
        }}
      >
        <AlertTextIcon alert={{ ...alert, type: "error" }} />
        <Grid className="mt-30 mb-5">
          <Button
            className="fs-16 m-5 w-100 shadow-none"
            variant="contained"
            color="error"
            loading={loading}
            onClick={() => {
              setLoading(true);
              alert.onConfirm && alert.onConfirm();
            }}
          >
            Yes
          </Button>
          <Button
            className="fs-16 m-5 min-w-120 shadow-none"
            variant="contained"
            onClick={() =>
              alert.onCancel !== undefined
                ? alert.onCancel
                : setAlert({ ...alert, isOpen: false })
            }
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default function Alert(props) {
  const { alert, setAlert } = props;

  return (
    <>
      {alert && (
        <Dialog show={alert.isOpen} maxWidth="xs">
          <Box
            sx={{
              minWidth: { xs: "auto", sm: "300px" },
              maxWidth: "400px",
            }}
          >
            {alert.type === "confirmation" ? (
              <ConfirmationCom {...{ alert, setAlert }} />
            ) : (
              <Grid
                container
                direction="column"
                sx={{
                  position: "relative",
                  textAlign: "center",
                }}
              >
                <AlertTextIcon {...{ alert }} />
                <Grid className="mt-20 p-10">
                  <Button
                    className="font-18 fw-6"
                    variant="contained"
                    sx={{ boxShadow: "none" }}
                    color={alert.type !== "" ? alert.type : "primary"}
                    fullWidth
                    onClick={() => {
                      setAlert({ ...alert, isOpen: false });
                      alert.onClick && alert.onClick();
                    }}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            )}
          </Box>
        </Dialog>
      )}
    </>
  );
}
