import React, { useContext } from "react";
import {
  AttachMoneyRounded,
  RequestQuoteRounded,
  ShoppingBasketRounded,
  ShoppingCartCheckoutRounded,
} from "@mui/icons-material";
import { Grid, IconButton, Paper } from "@mui/material";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const SalesAccWidget = ({ sellsItem, expenses, purchases }) => {
  const { t } = useTranslation();
  const [totalSells, setTotalSells] = useState(0);
  const [totalExps, setTotalExps] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalPurch, setTotalPurch] = useState(0);

  useEffect(() => {
    var monthlySells = sellsItem.filter(
      (j) =>
        moment(j.sellDate, "MM/DD/yyyy").format("MM/yyyy") ===
        moment().format("MM/yyyy")
    );
    setTotalSells(
      monthlySells
        .map((j) => j.qty * j.price)
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );
    setTotalProfit(
      monthlySells
        .map((j) =>
          j.qty * j.itemType === 1
            ? j.product.sellPrice - j.product.purchPrice
            : j.price
        )
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );
    setTotalPurch(
      purchases
        .filter(
          (j) =>
            moment(j.purchDate, "MM/DD/yyyy").format("MM/yyyy") ===
            moment().format("MM/yyyy")
        )
        .map((j) =>
          j.purchItems
            .map((x) => x.qty * x.purchPrice)
            .reduce((x, y) => x + y, 0)
        )
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );

    var monthlyExp = expenses.filter(
      (j) =>
        moment(j.addDate, "MM/DD/yyyy").format("MM/yyyy") ===
        moment().format("MM/yyyy")
    );
    setTotalExps(
      monthlyExp
        .map((j) => j.amount)
        .reduce((x, y) => x + y, 0)
        .toLocaleString()
    );
  }, [sellsItem, expenses, purchases]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Widget
          title={t("totalSales")}
          icon={<ShoppingCartCheckoutRounded />}
          value={totalSells}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Widget
          title={t("profit")}
          icon={<AttachMoneyRounded />}
          value={totalProfit}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Widget
          title={t("purchases")}
          icon={<ShoppingBasketRounded />}
          value={totalPurch}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Widget
          title={t("expense")}
          icon={<RequestQuoteRounded />}
          value={totalExps}
        />
      </Grid>
    </Grid>
  );
};

export default SalesAccWidget;

const Widget = (props) => {
  const { title, value, icon } = props;
  const { org } = useContext(AppContext);
  return (
    <Paper className="p-20">
      <div className="d-flex j-between a-center">
        <span className="gray-color fs-16 fw-5">{title}</span>
        <IconButton className="bg-primaryXS primary-color">{icon}</IconButton>
      </div>
      <div className="mt-20 mb-5">
        <span className="fs-16">{org && org.curSymbol} </span>
        <span className=" fw-5 fs-26">{value}</span>
      </div>
    </Paper>
  );
};
