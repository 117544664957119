import { Button, Grid, Paper, Typography, Box } from "@mui/material";
import React, { useContext, useState } from "react";
import { Input, InputDate, Select, InputPass, InputAuto } from "../../controls";
import { Form } from "../../layout";
import { DropLi, Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { MaxLength, RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditUser = (props) => {
  const { _post, _put, _getById, _getByObj } = props;
  const { t } = useTranslation();
  const { UserMdl } = Model();
  const navigate = useNavigate();
  const { stores, selStore } = useContext(AppContext);
  const { id } = useParams();
  const { Prefix, Periodic, Role, Gender, BoolType, Languages } = DropLi();
  const [storeLi, setStoreLi] = useState([]);

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpDate,
    handleInpPass,
    passValid,
  } = UseForm(UserMdl);

  useEffect(() => {
    id &&
      _getById(
        endpoints.User + controller.GetById,
        id,
        null,
        (res) =>
          res.status === 200 &&
          setValues((j) => ({ ...j, ...RmvEmptyProp(res.result) }))
      );

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setStoreLi([...stores.map((j) => ({ ...j, label: j.storeName }))]);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores]);

  const checkUserName = (value) => {
    if (value !== "") {
      const onComplete = (res) => {
        res.result === true &&
          setErrors({ ...errors, userName: t("userNameIsAlreadyExist") });
      };
      _getByObj(
        endpoints.User + controller.CheckUserName,
        { userId: values.id ? values.id : 0, value: value },
        actions.ActionType.Check,
        onComplete
      );
    }
  };

  const validateForm = () => {
    let temp = {};
    temp.firstName = values.firstName !== "" ? "" : t("firstNameIsRequired");
    temp.lastName = values.lastName !== "" ? "" : t("lastNameIsRequired");
    temp.userName =
      values.userName !== ""
        ? errors.userName !== undefined
          ? errors.userName
          : ""
        : t("userNameIsRequired");
    temp.prefix = values.prefix !== "" ? "" : t("prefixIsRequired");
    temp.gender = values.gender !== "" ? "" : t("genderIsRequired");
    temp.email =
      values.email !== ""
        ? /^$|.+@.+..+/.test(values.email)
          ? ""
          : t("emailIsNotValid")
        : t("emailIsRequired");
    temp.language = values.language !== "" ? "" : t("languageIsRequired");
    temp.role = values.role !== "" ? "" : t("roleIsRequired");
    temp.isActive = values.isActive !== "" ? "" : t("isActiveIsRequired");
    // Password Property required only account creating time.
    if (id === "undefined") {
      temp.password = values.password !== "" ? "" : t("passwordIsRequired");
      temp.confirmPass =
        values.confirmPass !== "" ? "" : t("confirmPassIsRequired.");
    }

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const onComplete = () => {
      navigate("/users");
      _getById(
        endpoints.User + controller.GetByStore,
        selStore,
        actions.ActionType.List,
        navigate("/users")
      );
    };
    if (validateForm()) {
      values.dob !== "" &&
        (values.dob = new Date(values.dob).toLocaleDateString());
      if (values.id === 0) {
        _post(
          endpoints.User,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      } else if (values.id !== 0) {
        _put(
          endpoints.User,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Box className="tx-center p-20">
          <Typography variant="h5" className="fs-26 gray-color fw-5 tx-upp">
            {id === "undefined" ? t("add") : t("update")} {t("user")}
          </Typography>
          <div className="bs-bottom mx-w-60 mt-5 m-auto" />
        </Box>
        {/* User Info. */}
        <div className="pt-20" />
        <Paper className="mt-30 shadow-xs br-8">
          <Grid container spacing={3} className="p-20 pt-0">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className="fs-22 fw-5 gray-color pl-10"
                sx={{ borderLeft: "5px double var(--primary-color)" }}
              >
                {t("userInformation")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Select
                label={t("prefix")}
                name="prefix"
                fullWidth
                value={values.prefix}
                error={errors.prefix}
                onChange={handleInpChg}
                options={Prefix}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Input
                label={t("firstName")}
                name="firstName"
                value={values.firstName}
                error={errors.firstName}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Input
                label={t("lastName")}
                name="lastName"
                value={values.lastName}
                error={errors.lastName}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                label={t("dateOfBirth")}
                name="dob"
                value={values.dob}
                error={errors.dob}
                onChange={(date) => handleInpDate("dob", date)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("email")}
                name="email"
                value={values.email}
                error={errors.email}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputAuto
                label={t("language")}
                name="language"
                value={values.language}
                error={errors.language}
                onChange={handleInpChg}
                options={Languages}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("phone")}
                name="phone"
                className="rmv-arrow"
                value={values.phone}
                error={errors.phone}
                onChange={handleInpChg}
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 15))
                }
                fullWidth
                inputProps={{ maxLength: 300 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label={t("gender")}
                name="gender"
                fullWidth
                value={values.gender}
                error={errors.gender}
                onChange={handleInpChg}
                options={Gender}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("SSN")}
                name="ssn"
                value={values.ssn}
                error={errors.ssn}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Address */}
        <div className="pt-20" />
        <Paper className="mt-30 shadow-xs br-8">
          <Grid container spacing={3} className="p-20 pt-0">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className="fs-22 fw-5 gray-color pl-10"
                sx={{ borderLeft: "5px double var(--primary-color)" }}
              >
                {t("address")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("city")}
                name="city"
                value={values.city}
                error={errors.city}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("state")}
                name="state"
                value={values.state}
                error={errors.state}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("ZIP")}
                name="zip"
                value={values.zip}
                error={errors.zip}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={t("address")}
                name="address"
                value={values.address}
                error={errors.address}
                onChange={handleInpChg}
                fullWidth
                inputProps={{ maxLength: 800 }}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Roles and Permissions */}
        <div className="pt-20" />
        <Paper className="mt-30 shadow-xs br-8">
          <Grid container spacing={3} className="p-20 pt-0">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className="fs-22 fw-5 gray-color pl-10"
                sx={{ borderLeft: "5px double var(--primary-color)" }}
              >
                {t("rolesAndPermissions")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label={t("store")}
                name="storeId"
                value={values.storeId}
                error={errors.storeId}
                onChange={handleInpChg}
                fullWidth
                options={storeLi}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label={t("role")}
                name="role"
                value={values.role}
                error={errors.role}
                onChange={handleInpChg}
                fullWidth
                options={Role}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label={t("isActive")}
                name="isActive"
                value={values.isActive}
                error={errors.isActive}
                onChange={handleInpChg}
                fullWidth
                options={BoolType}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={t("userName")}
                name="userName"
                value={values.userName}
                error={errors.userName}
                autoComplete="off"
                onChange={handleInpChg}
                onBlur={(e) => checkUserName(e.target.value)}
                fullWidth
                inputProps={{ maxLength: 400 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label={t("password")}
                name="password"
                value={values.password}
                onChange={handleInpPass}
                error={errors.password}
                className="input-primary w-100pr"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputPass
                label={t("confirmPassword")}
                name="confirmPass"
                value={values.confirmPass}
                onChange={(e) => handleInpPass(e, true)}
                error={
                  errors.confirmPass !== "" &&
                  values.password !== "" &&
                  !passValid.match &&
                  values.confirmPass !== ""
                    ? t("passwordAndConfirmPasswordNotMatch")
                    : errors.confirmPass
                }
                className="input-primary w-100pr"
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Salary Scale */}
        <div className="pt-20" />
        <Paper className="mt-30 shadow-xs br-8">
          <Grid container spacing={3} className="p-20 pt-0">
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                className="fs-22 fw-5 gray-color pl-10"
                sx={{ borderLeft: "5px double var(--primary-color)" }}
              >
                {t("salaryScale")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                label={t("salaryType")}
                name="salaryType"
                value={values.salaryType}
                error={errors.salaryType}
                onChange={handleInpChg}
                fullWidth
                options={Periodic}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                label={t("salary")}
                name="salary"
                type="number"
                className="rmv-arrow"
                value={values.salary}
                error={errors.salary}
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 9))}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} className="tx-right">
              <Button
                type="submit"
                className="btn-secondary"
                sx={{ width: { md: "150px", xs: "100%" } }}
                size="large"
              >
                {id === "undefined" ? t("submit") : t("saveChanges")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _stores: state.pos.stores,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditUser);
