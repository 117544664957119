import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, MenuPopup } from "../../layout";
import * as actions from "../../api/actions/pos";
import { Box, Grid, ListItemIcon, MenuItem } from "@mui/material";
import { ExpenseForm, CloseReg } from "../pos";
import {
  HomeRounded,
  PointOfSale,
  AttachMoneyRounded,
  CategoryRounded,
  CloseRounded,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { useContext } from "react";
import { AppContext } from "../../App";
import { Button, Select } from "../../controls";
import { useEffect } from "react";
import { Currencies } from "../../hooks/Currencies";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { _get, _authUser } = props;
  const { t } = useTranslation();
  const { setSelStore, selStore, stores, setStores } = useContext(AppContext);
  const navigate = useNavigate();
  const [posEl, setPosEl] = useState(null);
  const [expForm, setExpForm] = useState(false);
  const [regForm, setRegForm] = useState(false);

  useEffect(() => {
    _authUser.id &&
      _get(endpoints.Store + controller.AuthUserStores, null, (res) => {
        if (res.status === 200) {
          setStores([...res.result.map((j) => ({ ...j, label: j.storeName }))]);
          var defStore = res.result.find((j) => j.defStore) || res.result[0];
          if (defStore) {
            var currency = Currencies.find(
              (j) => j.id === _authUser.org.currency
            );
            setSelStore({
              ...defStore,
              currency: currency && currency.symbol_native,
            });
            setSelStore(defStore.id);
          }
        }
      });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  const handleChgStore = (e) => {
    var store = stores.find((j) => j.id === e.target.value);
    if (store) {
      setSelStore(store);
      setSelStore(store.id);
    }
  };

  const handleNavigate = (url) => {
    navigate(url);
  };
  const handlePos = (e) => {
    setPosEl(e.currentTarget);
  };

  return (
    <Box
      className="d-flex a-center w-100pr p-8"
      sx={{ justifyContent: { md: "space-between", xs: "end" } }}
    >
      <Box
        className="ml-10 gray-color"
        sx={{
          display: { md: "block", xs: "none" },
        }}
      >
        <div className="d-grid ">
          <span className="fs-14">{t("welcome")} 🎉</span>
          <span className="fw-5 fs-20">
            {_authUser && _authUser.firstName + " " + _authUser.lastName}
          </span>
        </div>
      </Box>
      <div className="d-flex a-center">
        {/* Shop */}
        {_authUser.role === 1 && (
          <Grid className="mr-10">
            <Select
              sx={{ minWidth: "150px" }}
              variant="outlined"
              size="small"
              name="shop"
              fullWidth
              value={selStore}
              onChange={handleChgStore}
              options={stores}
            />
          </Grid>
        )}
        {/* POS Menu */}
        <Grid>
          <Button
            // sx={{ height: "40px" }}
            size="large"
            variant="outlined"
            id="pos-menu"
            onClick={handlePos}
            startIcon={<PointOfSale />}
          >
            {t("POS")}
          </Button>
          <MenuPopup anchorEl={posEl} setAnchorEl={setPosEl} id="pos-menu">
            <MenuItem onClick={() => handleNavigate("/pos")}>
              <ListItemIcon>
                <HomeRounded fontSize="small" />
              </ListItemIcon>
              {t("POS")}
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/pos/categories")}>
              <ListItemIcon>
                <CategoryRounded fontSize="small" />
              </ListItemIcon>
              {t("category")}
            </MenuItem>
            <MenuItem onClick={() => setExpForm(true)}>
              <ListItemIcon>
                <AttachMoneyRounded fontSize="small" />
              </ListItemIcon>
              {t("expenses")}
            </MenuItem>
            <MenuItem onClick={() => setRegForm(true)}>
              <ListItemIcon>
                <CloseRounded fontSize="small" />
              </ListItemIcon>
              {t("closeRegister")}
            </MenuItem>
          </MenuPopup>
          {/* Dialog */}
          {expForm && (
            <Dialog show={expForm} onClose={false} maxWidth="xs">
              <ExpenseForm {...{ setShowForm: setExpForm }} />
            </Dialog>
          )}
          <Dialog
            show={regForm}
            onClose={false}
            maxWidth="md"
            sx={{
              "& .MuiDialog-paper": {
                margin: "15px",
              },
            }}
          >
            {regForm && <CloseReg {...{ setShowForm: setRegForm }} />}
          </Dialog>
        </Grid>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _stores: state.pos.stores,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = {
  _get: actions.get,
  _getById: actions.getById,
  _setStore: actions.clear,
};

export default connect(mapStateToProps, mapActiontoProps)(Header);
