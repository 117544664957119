import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "./";

const Categories = ({ _list, _type }) => {
  const navigate = useNavigate();
  const viewProducts = (id) => {
    navigate(`/pos/products/${id}`);
  };
  return (
    <Grid container spacing={3} className="pr-10">
      <Grid item xs={12}>
        <SearchBox type="categories" />
      </Grid>
      {_type === "categories" &&
        _list.map((cat, i) => (
          <Grid item xs={12} md={3} key={i}>
            <Paper
              onClick={() => viewProducts(cat.id)}
              sx={{ minHeight: "90px" }}
              className="br-8 shadow-xs p-20 d-flex j-center a-center c-pointer"
            >
              <Typography varing="h6" className="fs-22 fw-5 gray-color">
                {cat.name}
              </Typography>
            </Paper>
          </Grid>
        ))}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
});

const mapActionToProps = {};

export default connect(mapStateToProps, mapActionToProps)(Categories);
