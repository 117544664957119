import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const TopSellingProdTable = ({ sellsItem, inventories }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);

  const [topSellingProds, setTopSellingProds] = useState([]);
  useEffect(() => {
    const filteredProds = [];
    const _prods = sellsItem.filter((j) => j.itemType === 1);

    // Push Product
    _prods.forEach((j) => {
      var prod = _prods.filter((x) => x.product.id === j.product.id);
      // Check the product list is exist in the list before push to the list.
      !filteredProds.some((x) => JSON.stringify(x) === JSON.stringify(prod)) &&
        filteredProds.push(prod);
    });

    setTopSellingProds(
      filteredProds
        .sort(
          (f, s) =>
            s.map((j) => j.qty).reduce((x, y) => x + y, 0) -
            f.map((j) => j.qty).reduce((x, y) => x + y, 0)
        )
        .slice(0, 20)
    );
  }, [sellsItem]);

  return (
    <Paper>
      {/* Chart */}
      <Typography varint="h6" className="fs-16 fw-5 gray-color p-15">
        {t("top20SellingProducts")}
      </Typography>
      <TableContainer sx={{ maxHeight: "310px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  fontSize: "15px",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("productName")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("unitSell")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("unitCost")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("unitProfit")}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "15px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white-color)",
                  minWidth: "80px",
                  padding: "8px 8px",
                }}
              >
                {t("totalOnStock")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "200px" }}>
            {topSellingProds.map((item, i) => (
              <TableRow hover key={i}>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item[0].product.name}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.map((j) => j.qty).reduce((x, y) => x + y, 0)}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {org && org.curSymbol}{" "}
                  {item
                    .map((j) => Math.round(j.qty * j.price))
                    .reduce((x, y) => x + y, 0)
                    .toLocaleString()}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 8px 6px 8px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {org && org.curSymbol}{" "}
                  {item
                    .map((j) =>
                      Math.round(
                        j.qty * (j.product.sellPrice - j.product.purchPrice)
                      )
                    )
                    .reduce((x, y) => x + y, 0)
                    .toLocaleString()}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    padding: "6px 16px 6px 16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {inventories.find((j) => j.productId === item[0].productId) &&
                    inventories.find((j) => j.productId === item[0].productId)
                      .inStoreInventory}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TopSellingProdTable;
