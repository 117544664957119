import { QueryStatsRounded } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const StoreValueReport = ({ currentInvVal }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);

  return (
    <Paper className="p-15 bg-primary">
      <Box className="p-30 white-color">
        <Typography variant="h6" className="fs-20 fs-italic">
          {t("inStoreInventoryValue")}
        </Typography>
        <Box className="tx-center pt-40 pb-20">
          <QueryStatsRounded sx={{ fontSize: "100px" }} />
          <Typography
            variant="h5"
            className="fw-5 pt-20"
            sx={{ fontSize: "30px" }}
          >
            {(org && org.curSymbol) + " " + currentInvVal}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default StoreValueReport;
