import { CloseRounded } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const ViewProd = (props) => {
  const { setShowForm, viewData } = props;
  const { t } = useTranslation();
  const { org } = useContext(AppContext);

  return (
    <div>
      <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
        <div>
          <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
            {t("saleDetails")}
          </h1>
        </div>

        <div>
          <IconButton onClick={() => setShowForm(false)}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("itemName")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("price")}</TableCell>
                  <TableCell>{t("total")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData.sellItems.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {item.itemType === 1 ? item.product.name : "Lottery"}
                    </TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {(org && org.curSymbol) + " " + item.price}
                    </TableCell>
                    <TableCell>
                      {(org && org.curSymbol) + " " + item.qty * item.price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="w-100pr"
          sx={{
            borderRight: { sm: "1px solid var(--grayLight-color)" },
          }}
        >
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("transectionDate")}</TableCell>
                  <TableCell align="right">{viewData.sellDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("paymentType")}</TableCell>
                  <TableCell align="right">{viewData.paymentType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("cashier")}</TableCell>
                  <TableCell align="right">{viewData.username}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} className="w-100pr">
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("totalItem")}</TableCell>
                  <TableCell align="right">{viewData.totalItem}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("subtotal")}</TableCell>
                  <TableCell align="right">
                    {(org && org.curSymbol) + " " + viewData.subtotal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t("tax")}({viewData.taxPct}%)
                  </TableCell>
                  <TableCell align="right">
                    {(org && org.curSymbol) + " " + viewData.tax}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalPay")}</TableCell>
                  <TableCell align="right">
                    {(org && org.curSymbol) + " " + viewData.totalPay}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("paymentReceived")}</TableCell>
                  <TableCell align="right">
                    {(org && org.curSymbol) + " " + viewData.received}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("change")}</TableCell>
                  <TableCell align="right">
                    {(org && org.curSymbol) + " " + viewData.change}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewProd;
