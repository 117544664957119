import { SearchRounded } from "@mui/icons-material";
import { Box, IconButton, InputAdornment } from "@mui/material";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Input } from "../../controls";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const SearchBox = (props) => {
  const { _getByObj, _getById, type = "product", _setVal, handleCart } = props;
  const [searchVal, setSearchVal] = useState("");
  const { id } = useParams();
  const { selStore } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    // Set Focuse in Searchbox
    document.getElementById("searchBox").focus();
    // Get Data
    if (id !== undefined) {
      _getByObj(
        endpoints.Inventory + controller.GetProdsByCat,
        { storeId: selStore, catId: id },
        actions.ActionType.List
      );
    } else if (type === "product") getDefPosItem();
    else if (type === "categories") getCategories();

    return () => {
      _setVal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    if (type === "product")
      searchVal ? getFromSever(searchVal) : getDefPosItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  const handleSearch = (e) => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result.length === 1) {
        handleCart(res.result[0]);
      }
      setSearchVal("");
    };
    if (e.target.value && e.which === 13)
      getFromSever(e.target.value, onComplete);
    else if (type !== "product") getCategories();
  };

  const getFromSever = (value, onComplete) => {
    _getByObj(
      endpoints.Inventory +
        (type === "product"
          ? controller.FindProducts
          : controller.GetCatsByStore),
      { storeId: selStore, value: value },
      actions.ActionType.List,
      onComplete
    );
  };

  const getDefPosItem = () => {
    const onComplete = (res) => {
      if (res.status === 200 && res.result.length > 0) {
        _setVal(
          { result: res.result, type: "inventories" },
          actions.ActionType.List
        );
      } else {
        getRandomProd();
      }
    };
    selStore &&
      _getById(
        endpoints.PosItem + controller.GetByStoreId,
        selStore,
        null,
        onComplete
      );
  };

  const getRandomProd = () => {
    const onComplete = (res) => {
      res.status === 200 &&
        _setVal(
          { result: res.result, type: "inventories" },
          actions.ActionType.List
        );
    };
    _getById(
      endpoints.Inventory + controller.GetProdsByStore,
      selStore,
      null,
      onComplete
    );
  };

  const getCategories = () => {
    selStore &&
      _getByObj(
        endpoints.Inventory + controller.GetCatsByStore,
        { storeId: selStore },
        actions.ActionType.List
      );
  };

  return (
    <Box className="shadow-xs mb-30" sx={{ borderTopRightRadius: "10px" }}>
      <Input
        label={t("enterNameOrScanBarcode")}
        name="barcode"
        id="searchBox"
        sx={{
          borderTopRightRadius: "10px",
          " .MuiFilledInput-root": {
            backgroundColor: "#fff !important",
          },
        }}
        value={searchVal}
        onChange={(e) => setSearchVal(e.target.value)}
        onKeyDown={handleSearch}
        fullWidth
        autoFocus={true}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchRounded />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

const mapStateToProps = (state) => ({
  _defStore: state.pos.defStore,
  _type: state.pos.type,
});

const mapActionToProps = {
  _getByObj: actions.getByObj,
  _getById: actions.getById,
  _setVal: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(SearchBox);
