import { ClearRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { DropLi } from "../../hooks";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";

const SalaryHistory = (props) => {
  const { _clear, _getById, _list, _type, _delete } = props;
  const { t } = useTranslation();
  const { Periodic } = DropLi();
  const { setLoader, selStore } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("dltTitle"),
      onConfirm: () => {
        const onComplete = (res) => {
          res.status === 200
            ? setAlert({
                isOpen: true,
                type: "success",
                title: t("dltSuccessTitle"),
                subTitle: res.msg,
              })
            : setAlert({
                isOpen: true,
                type: "error",
                title: t("error"),
                subTitle: res.msg,
              });
        };
        _delete(endpoints.Salary, id, actions.ActionType.RmvList, onComplete);
      },
    });
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(endpoints.Salary, selStore, actions.ActionType.List, () =>
        setLoader(false)
      );
    }
    return () => {
      _clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          className="btn-error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "firstName", label: t("firstName") },
          { id: "lastName", label: t("lastName") },
          { id: "dob", label: t("dateOfBirth") },
          { id: "salaryType", label: t("salaryType") },
          { id: "salary", label: t("salary") },
          { id: "payDate", label: t("payPeriod") },
          { id: "totalHours", label: t("totalWorkHour") },
          { id: "totalSalary", label: t("totalSalary") },
        ]}
        tblData={
          _type === "salaryHistories"
            ? _list.map((obj) => {
                return {
                  ...obj,
                  firstName: obj.user.firstName,
                  lastName: obj.user.lastName,
                  dob: obj.user.dob,
                  salaryType:
                    obj.user.salaryType &&
                    Periodic.find((j) => j.id === obj.user.salaryType).name,
                  salary: obj.user.salary,
                };
              })
            : []
        }
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(SalaryHistory);
