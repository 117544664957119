import { Container, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { InputCheck } from "../../controls";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import SearchBox from "../pos/SearchBox";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { useTranslation } from "react-i18next";

const PosSettings = (props) => {
  const { _list, _getById, _type, _set, _post, _delete } = props;
  const { t } = useTranslation();
  const { setLoader, selStore } = useContext(AppContext);
  const [posItem, setPosItem] = useState([]);

  useEffect(() => {
    setPosItem([]);
    if (selStore) {
      setLoader(true);
      const onComplete = (res) => {
        _set(
          { result: res.result.map((j) => j.product), type: "posItems" },
          actions.ActionType.List
        );
        setLoader(false);
      };
      _getById(
        endpoints.Inventory + controller.GetProdsByStore,
        selStore,
        null,
        onComplete
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    const onComplete = (res) => {
      if (res.status === 200) {
        _list.length > 0 &&
          setPosItem(
            _list.map((j) => {
              return {
                id: j.id,
                name: j.name,
                checked: res.result.some((x) => x.productId === j.id),
              };
            })
          );
      }
    };

    selStore &&
      _type === "posItems" &&
      _getById(
        endpoints.PosItem + controller.GetByStoreId,
        selStore,
        null,
        onComplete
      );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_list]);

  const handleChange = (e, prodId) => {
    e.target.checked
      ? _post(
          endpoints.PosItem,
          ToFormData(RmvEmptyProp({ productId: prodId, storeId: selStore }))
        )
      : _delete(endpoints.PosItem, prodId, null);
  };

  return (
    <Container maxWidth="md">
      <Paper className="br-8 shadow-xs p-20">
        <Box className="tx-center mb-20">
          <Typography variant="subtitle1" className="fs-22 fw-5 gray-color">
            {t("allItems")}
          </Typography>
          <div className="bs-bottom mx-w-60 m-auto" />
        </Box>
        <Box className="tx-center mb-20">
          <SearchBox />
        </Box>

        <Box
          sx={{
            height: "calc(100vh - 300px)",
          }}
        >
          {posItem.map((prod, i) => (
            <div key={i}>
              <InputCheck
                onChange={(e) => handleChange(e, prod.id)}
                label={prod.name}
                defaultChecked={prod.checked}
              />
              <Divider />
            </div>
          ))}
        </Box>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _defStore: state.pos.defStore,
  _type: state.pos.type,
});

const mapActionToProps = {
  _post: actions.post,
  _delete: actions.Delete,
  _getById: actions.getById,
  _set: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(PosSettings);
