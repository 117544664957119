import React from "react";
import { Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { useState } from "react";
import { Box } from "@mui/system";
import { IconButton, Tooltip } from "@mui/material";
import { VisibilityRounded } from "@mui/icons-material";
import ViewReturnItems from "../purchase/return/ViewReturnItems";
import { useTranslation } from "react-i18next";

const ReturnProd = (props) => {
  const { _getById } = props;
  const { t } = useTranslation();
  const { setLoader, selStore, org } = useContext(AppContext);
  const [returns, setReturns] = useState([]);
  const [viewData, setViewData] = useState();
  const [showForm, setShowForm] = useState(false);

  const onComplete = (res) => {
    setLoader(false);
    if (res.status === 200) setReturns(res.result);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.PurchReturn + controller.GetByStore,
        selStore,
        actions.ActionType.List,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleView = (data) => {
    setViewData(data);
    setShowForm(true);
  };

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("view")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "actions", label: t("view") },
          { id: "supplier", label: t("supplier") },
          { id: "totalItem", label: t("totalItem") },
          { id: "totalPrice", label: t("totalPrice") },
          { id: "returnUser", label: t("returnBy") },
          { id: "returnDate", label: t("date") },
        ]}
        tblData={returns.map((item) => {
          return {
            ...item,
            supplier: item.purchase.supplier && item.purchase.supplier.company,
            totalItem: item.purchReturnItems
              .map((j) => j.qty)
              .reduce((x, y) => x + y, 0),
            totalPrice:
              (org && org.curSymbol) +
              " " +
              item.purchReturnItems
                .map((j) => j.qty * j.product.purchPrice)
                .reduce((x, y) => x + y, 0),
            returnUser: item.user.firstName + " " + item.user.lastName,
          };
        })}
      />

      {/* Edit Dialog */}
      {viewData && (
        <Dialog show={showForm} onClose={false} maxWidth="lg">
          <ViewReturnItems {...{ setShowForm, viewData }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ReturnProd);
