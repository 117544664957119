import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  Button,
  Input,
  InputAuto,
  InputDate,
  InputFile,
  InputQty,
  Select,
} from "../../controls";
import { Alert, Dialog, Form } from "../../layout";
import { DropLi, Model, UseForm } from "../../hooks";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { ClearRounded, PercentRounded } from "@mui/icons-material";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { useEffect } from "react";
import {
  MaxLength,
  NestedFormData,
  RmvArryEmptyProp,
} from "../../hooks/Method";
import AddEditProd from "../products/AddEditProd";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddMultPurch = (props) => {
  const { _post, _put, _getById, _getByObj, _authUser } = props;
  const { PurchaseMdl } = Model();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader, selStore } = useContext(AppContext);
  const { PaymentType } = DropLi();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleFile,
    handleInpChg,
    handleInpDate,
  } = UseForm(PurchaseMdl);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [searchVal, setSearchVal] = useState("");
  const [showPurchForm, setShowPurchForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [searchLi, setSearchLi] = useState([]);
  const [netAmount, setNetAmount] = useState(0);
  const [findErr, setFindErr] = useState("");
  const { id } = useParams();

  useEffect(() => {
    // Set Focuse in Searchbox
    document.getElementById("searchBox").focus();

    if (selStore) {
      const onComplete = (res) => {
        setLoader(false);
        res.status === 200 &&
          setSuppliers([
            ...res.result.map((j) => {
              return {
                id: j.id,
                label: `${j.firstName} ${j.lastName} (${j.phone})`,
              };
            }),
          ]);
      };
      setLoader(true);
      _getById(
        endpoints.Supplier + controller.GetByStore,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  useEffect(() => {
    var netAmount = values.purchItems
      .map((j) => j.qty * j.purchPrice)
      .reduce((x, y) => x + y, 0);
    var discount = (parseFloat(values.discount || 0) / 100) * netAmount;
    setNetAmount((netAmount - discount).toFixed(2));
  }, [values, values.discount]);

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 &&
        res.result.purchItems &&
        setValues({
          ...res.result,
          discount: res.result.discount || "",
          paymentDue: res.result.paymentDue || "",
          recept: res.result.recept || "",
        });
    };

    if (id && selStore)
      _getByObj(
        endpoints.Purchase + controller.GetById,
        { id: id, storeId: selStore },
        null,
        onComplete
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selStore]);

  const handlePurchForm = () => {
    setShowPurchForm(!showPurchForm);
  };

  const validateForm = () => {
    let temp = {};
    temp.supplierId = values.supplierId !== "" ? "" : t("supplierIsRequired");
    temp.paymentType =
      values.paymentType !== "" ? "" : t("paymentTypeIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = () => {
    if (validateForm() && values.purchItems.length > 0) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        res.status === 201 || res.status === 200
          ? _getById(
              endpoints.Purchase + controller.GetByStore,
              selStore,
              actions.ActionType.List,
              navigate("/purchases")
            )
          : setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
      };
      values.userId = _authUser.id;
      if (id) {
        _put(
          endpoints.Purchase,
          NestedFormData(RmvArryEmptyProp(values)),
          null,
          onComplete
        );
      } else {
        values.storeId = selStore;
        _post(
          endpoints.Purchase,
          NestedFormData(RmvArryEmptyProp(values)),
          null,
          onComplete
        );
      }
    } else if (values.purchItems.length <= 0)
      setAlert({
        isOpen: true,
        type: "error",
        title: t("emptyPurchaseList"),
        subTitle: t("purchWarning"),
      });
  };

  const GetDataFromServer = (val, onComplete) => {
    // Get Data From Server
    val &&
      _getByObj(
        endpoints.Product + controller.FindProducts,
        {
          storeId: selStore,
          value: val,
        },
        null,
        onComplete
      );
  };

  const handleSearch = (e, val) => {
    setSearchVal(val);
    const onComplete = (res) => {
      if (res.status === 200) {
        setSearchLi([
          ...res.result.map((j) => {
            return {
              ...j,
              id: j.id,
              label: `${j.name} (${j.barcode})`,
            };
          }),
        ]);
        setFindErr("");
      }
    };
    e && GetDataFromServer(e.target.value, onComplete);
  };

  const handleScan = (e) => {
    const onComplete = (res) => {
      const product = res.result[0];
      if (res.status === 200 && product) handleSelect(null, product);
      else setFindErr(t("productNotFound"));
    };
    GetDataFromServer(e.target.value, onComplete);
    setSearchVal("");
  };

  const handleSelect = (e, product) => {
    if (product) {
      if (!values.purchItems.some((j) => j.productId === product.id)) {
        setValues((x) => ({
          ...x,
          purchItems: [
            {
              productId: product.id,
              qty: 1,
              purchPrice: product.purchPrice,
              product: product,
            },
            ...x.purchItems,
          ],
        }));
      } else if (values.purchItems.some((j) => j.productId === product.id)) {
        setValues((x) => ({
          ...x,
          purchItems: x.purchItems.map((j) =>
            j.productId === product.id ? { ...j, qty: parseInt(j.qty) + 1 } : j
          ),
        }));
      }
      setFindErr("");
    }
    setSearchVal("");
  };

  const handleQtyChg = (e, productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId ? { ...j, qty: Math.abs(e.target.value) } : j
      ),
    }));
  };

  const handleInc = (productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId ? { ...j, qty: parseInt(j.qty) + 1 } : j
      ),
    }));
  };
  const handleDec = (productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId
          ? { ...j, qty: j.qty > 1 ? parseInt(j.qty) - 1 : j.qty }
          : j
      ),
    }));
  };

  const handleRmvItem = (prodId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.filter((j) => j.productId !== prodId),
    }));
  };

  const handlePurchPrice = (e, productId) => {
    setValues((x) => ({
      ...x,
      purchItems: x.purchItems.map((j) =>
        j.productId === productId ? { ...j, purchPrice: e.target.value } : j
      ),
    }));
  };

  return (
    <>
      <Form noValidate>
        <Box className="tx-center p-20">
          <Typography variant="h5" className="fs-26 gray-color fw-5 tx-upp">
            {t("purchases")}
          </Typography>
          <div className="bs-bottom mx-w-80 mt-5 m-auto" />
        </Box>

        {/* Items */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-20">
          {/* Title Bar */}
          <Box className="d-flex j-between">
            <Typography
              variant="subtitle1"
              className="fs-18 fw-5 gray-color pl-10 mb-20"
              sx={{ borderLeft: "5px double var(--primary-color)" }}
            >
              {t("purchaseProducts")}
            </Typography>
            <div>
              <Button variant="text" onClick={handlePurchForm}>
                {t("addNewProduct")}
              </Button>
            </div>
          </Box>

          {/* Search Box */}
          <Autocomplete
            id="searchBox"
            options={searchLi}
            onChange={handleSelect}
            inputValue={searchVal}
            onInputChange={handleSearch}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            onKeyDown={(e) => e.which === 13 && handleScan(e, searchVal)}
            componentsProps={{
              paper: {
                sx: {
                  borderRadius: "0px 0px 10px 10px",
                  backgroundColor: "var(--white-color)",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                variant="filled"
                label={t("searchByNameOrBarcode")}
                {...(findErr && { error: true, helperText: findErr })}
                {...params}
              />
            )}
          />

          {/* Product Details */}
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("actions")}</TableCell>
                  <TableCell>{t("productName")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("purchasePrice")}</TableCell>
                  <TableCell>{t("subtotal")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.purchItems.map((prod, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Tooltip arrow title={t("delete")}>
                        <IconButton
                          onClick={() => handleRmvItem(prod.productId)}
                          size="small"
                          className="btn-error"
                        >
                          <ClearRounded className="fs-14" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{prod.product && prod.product.name}</TableCell>
                    <TableCell>
                      <InputQty
                        value={prod.qty}
                        onIncrease={() => handleInc(prod.productId)}
                        onDecrease={() => handleDec(prod.productId)}
                        onChange={(e) => handleQtyChg(e, prod.productId)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        className="rmv-arrow"
                        value={prod.purchPrice}
                        sx={{
                          "& .MuiInputBase-root": {
                            maxWidth: "120px",
                            padding: "0px 0px",
                          },
                          "& .MuiInputBase-input": {
                            padding: "5.5px 0px",
                            textAlign: "center",
                          },
                        }}
                        onChange={(e) => handlePurchPrice(e, prod.productId)}
                      />{" "}
                    </TableCell>
                    <TableCell className="fs-16">
                      ${(prod.qty * prod.purchPrice).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box className="tx-right p-10 tx-upp">
              <Typography className="fs-16 gray-color fw-5">
                {t("totalItem")}:&nbsp;
                {values.purchItems.length > 0
                  ? values.purchItems
                      .map((j) => j.qty)
                      .reduce((x, y) => x + y, 0)
                  : 0}
              </Typography>
              <Typography className="fs-20 gray-color fw-6">
                {t("netAmount")}: ${netAmount}
              </Typography>
            </Box>
          </TableContainer>
        </Paper>

        {/* Purchase Details */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-20">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputAuto
                label={t("supplier")}
                name="supplierId"
                options={suppliers}
                value={values.supplierId}
                error={errors.supplierId}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputDate
                label={t("purchaseDate")}
                name="purchDate"
                value={values.purchDate}
                error={errors.purchDate}
                onChange={(date) =>
                  handleInpDate(
                    "purchDate",
                    new Date(date).toLocaleDateString()
                  )
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Select
                label={t("paymentType")}
                name="paymentType"
                fullWidth
                value={values.paymentType}
                error={errors.paymentType}
                onChange={handleInpChg}
                options={PaymentType}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label={t("paymentDue")}
                className="rmv-arrow"
                name="paymentDue"
                type="number"
                onInput={(e) =>
                  (e.target.value = MaxLength(e.target.value, 12))
                }
                value={values.paymentDue}
                error={errors.paymentDue}
                onChange={handleInpChg}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input
                label={t("discount")}
                type="number"
                onInput={(e) => (e.target.value = MaxLength(e.target.value, 3))}
                name="discount"
                className="rmv-arrow"
                value={values.discount}
                error={errors.discount}
                onChange={handleInpChg}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentRounded />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputFile
                label={t("uploadReceipt")}
                name="receptFile"
                src={values.recept}
                values={values.recept}
                onChange={handleFile}
                rmv={() => {
                  setValues({ ...values, receptFile: "", recept: "" });
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Grid item xs={12} className="tx-right mt-20">
          <Button
            loading={loading}
            onClick={handleSubmit}
            sx={{ width: { md: "200px", xs: "100%" } }}
            size="large"
          >
            {id ? t("saveChanges") : t("submit")}
          </Button>
        </Grid>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      {/* Edit Dialog */}
      {showPurchForm && (
        <Dialog show={showPurchForm} maxWidth="md">
          <div className="bg-grayXS">
            <AddEditProd {...{ setShowPurchForm }} />
          </div>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _stores: state.pos.stores,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddMultPurch);
