import React, { useContext, useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import { Button, Input, InputAuto, InputMultImg, Select } from "../../controls";
import { DropLi, Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { PercentRounded, ViewWeekRounded } from "@mui/icons-material";
import { controller, endpoints } from "../../api/actions/api";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import {
  MaxLength,
  NestedFormData,
  RmvEmptyProp,
  ToFormData,
} from "../../hooks/Method";
import { Alert, Snackbar } from "../../layout";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditProd = (props) => {
  const { _post, _put, _get, _getById, _authUser, setShowPurchForm } = props;
  const { t } = useTranslation();
  const { ProdMdl } = Model();
  const { id } = useParams();
  const { selStore } = useContext(AppContext);
  const { UnitType, BoolType } = DropLi();
  const navigate = useNavigate();
  const [cats, setCats] = useState([]);
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });
  const [loader, setLoader] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleFile,
    resetFormControls,
  } = UseForm(ProdMdl);
  const [mainCats, setMainCats] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [imgSrc, setImgSrc] = useState([]);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("nameIsRequired");
    temp.barcode = values.barcode !== "" ? "" : t("barcodeIsRequired");
    temp.unit = values.unit !== "" ? "" : t("unitTypeIsTequired");
    temp.tax = values.tax !== "" ? "" : t("taxIsRequired");
    temp.purchPrice =
      values.purchPrice !== "" ? "" : t("purchasePriceIsIequired");
    temp.sellPrice = values.sellPrice !== "" ? "" : t("sellPriceIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (isSaveExit = false) => {
    if (validateForm()) {
      setLoader(true);
      const onComplete = (res) => {
        setLoader(false);
        if (res.status === 201 || res.status === 200) {
          if (isSaveExit) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("success"),
              subTitle: res.msg,
              onClick: () => navigate("/products"),
            });
          } else {
            resetFormControls();
            setImgSrc([]);
            setSnackbar({
              type: "success",
              show: true,
              msg: t("prodAddTitle"),
            });
          }
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      };
      if (id === undefined) {
        values.userId = _authUser.id;
        values.storeId = selStore;
        _post(
          endpoints.Product,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      } else if (id !== 0) {
        _put(
          endpoints.Product,
          NestedFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      }
    }
  };

  const handleGenerateCode = (name) => {
    setValues({
      ...values,
      [name]: Math.floor(10000000000 + Math.random() * 10000000000),
    });
  };

  // Calculate Sell Price
  useEffect(() => {
    if (values.id === 0 && values.purchPrice && values.profitMargin) {
      var purchPrice = parseFloat(values.purchPrice);
      var sellPrice =
        purchPrice + (parseFloat(values.profitMargin) / 100) * purchPrice;
      setValues((x) => ({
        ...x,
        sellPrice: sellPrice.toFixed(2),
      }));
      setErrors((x) => ({ ...x, sellPrice: "" }));
    } else if (values.id && values.id !== 0) {
      setValues((x) => ({ ...x, sellPrice: values.sellPrice }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.purchPrice, values.profitMargin]);

  useEffect(() => {
    // Get Categories
    _get(
      endpoints.Category,
      null,
      (res) => res.status === 200 && setCats([...res.result])
    );

    // Get Product Data By Product-Id
    if (id) {
      _getById(
        endpoints.Product + controller.GetById,
        id,
        actions.ActionType.Data,
        (res) => {
          if (res.status === 200 && res.result) {
            const val = RmvEmptyProp(res.result);
            setValues((x) => ({ ...x, ...val }));
            setImgSrc(val.imgs.map((j) => ({ ...j, src: j.imgUrl })));
          }
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setMainCats([
      ...cats
        .filter((j) => !j.parentCatId)
        .map((j) => ({ ...j, label: j.name })),
    ]);
    return () => {};
  }, [cats]);

  // Set Subcategory
  useEffect(() => {
    setSubCat([
      ...cats
        .filter((j) => j.parentCatId === values.categoryId)
        .map((j) => ({ ...j, label: j.name })),
    ]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryId]);

  return (
    <>
      <Paper>
        <div className="mb-10 d-flex flex-wrap g-20 j-between a-center pt-10 pl-10 pr-10">
          <div>
            <span className="fs-20 fw-5 fw-4 gray-color tx-upp border-left">
              {id === undefined ? t("add") : t("update")} {t("Product")}
            </span>
          </div>

          <div>
            {setShowPurchForm && (
              <Button
                loading={loader}
                onClick={() => setShowPurchForm(false)}
                variant="text"
                sx={{
                  width: { md: "150px", xs: "100%" },
                  mr: { md: "10px", xs: "0px" },
                  mb: { md: "0px", xs: "10px" },
                }}
              >
                {t("close")}
              </Button>
            )}
            {id === undefined && !setShowPurchForm && (
              <Button
                loading={loader}
                onClick={() => handleSubmit(true)}
                variant="text"
                sx={{
                  minWidth: { md: "150px", xs: "100%" },
                  mr: { md: "10px", xs: "0px" },
                  mb: { xs: "10px", md: "0px" },
                }}
                className="pr-10 pl-10"
              >
                {t("saveAndExit")}
              </Button>
            )}
            <Button
              loading={loader}
              onClick={() => handleSubmit(id === undefined ? false : true)}
              sx={{ minWidth: { md: "230px", xs: "100%" } }}
              variant="text"
            >
              {id === undefined ? t("saveAndAddAnother") : t("saveChanges")}
            </Button>
          </div>
        </div>
        <Divider />
        <Grid container spacing={3} className="p-20 pt-0">
          <Grid item xs={12} md={4}>
            <Input
              label={t("barcode")}
              name="barcode"
              value={values.barcode}
              error={errors.barcode}
              onChange={handleInpChg}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip arrow title={t("generateBarcode")}>
                      <IconButton onClick={() => handleGenerateCode("barcode")}>
                        <ViewWeekRounded />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Input
              label={t("productName")}
              name="name"
              value={values.name}
              error={errors.name}
              onChange={handleInpChg}
              fullWidth
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputAuto
              label={t("category")}
              name="categoryId"
              fullWidth
              value={values.categoryId}
              error={errors.categoryId}
              onChange={handleInpChg}
              options={mainCats}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputAuto
              fullWidth
              label={t("subcategory")}
              name="parentCatId"
              value={values.parentCatId}
              error={errors.parentCatId}
              onChange={handleInpChg}
              options={subCat}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label={t("unitType")}
              name="unit"
              fullWidth
              value={values.unit}
              error={errors.unit}
              onChange={handleInpChg}
              options={UnitType}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label={t("tax")}
              name="tax"
              fullWidth
              value={values.tax}
              error={errors.tax}
              onChange={handleInpChg}
              options={BoolType}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={t("purchasePrice")}
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
              name="purchPrice"
              value={values.purchPrice}
              error={errors.purchPrice}
              onChange={handleInpChg}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={t("profitMargin")}
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 3))}
              name="profitMargin"
              className="rmv-arrow"
              value={values.profitMargin}
              error={errors.profitMargin}
              onChange={handleInpChg}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentRounded />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Input
              label={t("sellPrice")}
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 12))}
              name="sellPrice"
              value={values.sellPrice}
              error={errors.sellPrice}
              onChange={handleInpChg}
              fullWidth
              required
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Input
              label={t("alertQty")}
              type="number"
              name="alertQty"
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 10))}
              value={values.alertQty}
              error={errors.alertQty}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Input
              label={t("productDescription")}
              multiline
              rows={4}
              name="descr"
              value={values.descr}
              error={errors.descr}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <div className="d-flex flex-wrap g-10">
              <InputMultImg
                label={t("productImages")}
                name="imgsFile"
                maxUpl={5}
                src={imgSrc}
                values={values.imgsFile}
                onChange={handleFile}
                rmv={(file) => {
                  setValues((x) => ({
                    ...x,
                    imgsFile: x.imgsFile.filter(
                      (j) => j.lastModified !== file.lastModified
                    ),
                    imgs: x.imgs.filter((j) => j.id !== file.id),
                  }));
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditProd);
