import { useTranslation } from "react-i18next";

export default function DropLi() {
  const { t } = useTranslation();

  const Gender = [
    { id: 1, label: t("male") },
    { id: 2, label: t("female") },
    { id: 3, label: t("other") },
  ];

  const SalesBy = [
    { id: 1, label: t("today") },
    { id: 2, label: t("thisWeek") },
    { id: 3, label: t("thisMonth") },
    { id: 4, label: t("thisYear") },
  ];

  const Languages = [
    { id: "en", label: "English" },
    { id: "ar", label: "العربية (Arabic)" },
    { id: "bn", label: "বাংলা (Bangla)" },
    { id: "hi", label: "हिन्दी (Hindi)" },
    { id: "zh", label: "中文 (Chinese)" },
    { id: "fr", label: "Français (French)" },
    { id: "es", label: "Español (Spanish)" },
  ];

  const UnitType = [
    { id: 1, label: t("box") },
    { id: 2, label: t("piece") },
  ];

  const PaymentType = [
    { id: 1, label: t("fullPaid") },
    { id: 2, label: t("due") },
  ];

  const PayType = [
    { id: 1, label: t("cash") },
    { id: 2, label: t("credit") },
  ];

  const BoolType = [
    { id: 1, label: t("yes") },
    { id: 2, label: t("no") },
  ];

  const ReturnReason = [
    { id: 1, label: t("dateExpire") },
    { id: 2, label: t("badItem") },
    { id: 3, label: t("damage") },
    { id: 4, label: t("extra") },
    { id: 5, label: t("noSale") },
    { id: 6, label: t("others") },
  ];

  const Prefix = [
    { id: 1, label: t("mr") },
    { id: 2, label: t("mrs") },
    { id: 3, label: t("miss") },
  ];

  const Periodic = [
    { id: 1, label: t("daily") },
    { id: 2, label: t("monthly") },
    { id: 3, label: t("yearly") },
  ];

  const Role = [
    { id: 1, label: t("admin") },
    { id: 2, label: t("manager") },
    { id: 3, label: t("cashier") },
  ];

  const PordType = [
    { id: 1, label: t("products") },
    { id: 2, label: t("category") },
    { id: 3, label: t("brands") },
  ];

  return {
    SalesBy,
    UnitType,
    BoolType,
    PaymentType,
    PayType,
    ReturnReason,
    Gender,
    Prefix,
    Periodic,
    Role,
    PordType,
    Languages,
  };
}
