import { TextField } from "@mui/material";
import React from "react";

export default function Input(props) {
  const {
    name,
    label,
    value,
    variant,
    onChange,
    error = null,
    required = false,
    fullWidth = true,
    ...other
  } = props;
  return (
    <TextField
      variant={variant || "filled"}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      fullWidth={fullWidth}
      required={required}
      sx={{
        "& .MuiFilledInput-root::before": {
          borderBottom: `1px solid var(--grayMD-color)`,
        },
      }}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
