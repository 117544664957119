import { AttachMoneyRounded } from "@mui/icons-material";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "../../controls";
import * as actions from "../../api/actions/pos";
import { Model } from "../../hooks";
import { endpoints } from "../../api/actions/api";
import { NestedFormData, PrintDiv, RmvEmptyProp } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const Checkout = (props) => {
  const {
    subTotal,
    cartItems,
    totalPay,
    tax,
    setReceptData,
    handleClearCart,
    _post,
    _authUser,
    setAlert,
  } = props;
  const { SellMdl } = Model();
  const navigate = useNavigate();
  const [sellData, setSellData] = useState(SellMdl());
  const [receivedPay, setReceivedPay] = useState("");
  const [change, setChange] = useState(0);
  const [loading, setLoading] = useState(false);
  const { selStore, stores, org } = useContext(AppContext);
  const [store, setStore] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setStore(stores.find((j) => j.id === selStore));
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleInpChange = (e) => {
    setReceivedPay(e.target.value);
  };

  const handleNum = (e, val) => {
    setReceivedPay((x) => x + (val !== undefined ? val : e.target.innerText));
  };

  const clear = () => {
    setReceivedPay("");
  };

  const handleSubmit = () => {
    if (
      sellData.sellItems.length > 0 &&
      sellData.received !== "" &&
      parseFloat(receivedPay) >= totalPay
    ) {
      setLoading(true);
      const onComplete = (res) => {
        if (res.status === 200) {
          setReceptData(res.result);
          setTimeout(() => {
            PrintDiv("printSellRecept");
            setLoading(false);
            handleClearCart();
          }, 1500);
        } else {
          setLoading(false);
          setAlert({
            isOpen: true,
            type: "warning",
            title: t("warning"),
            subTitle: res.msg,
          });
        }
      };
      sellData.sellItems = sellData.sellItems.map((j) =>
        j.itemType === 2 ? { ...j, productId: "" } : j
      );
      _post(
        endpoints.Sell,
        NestedFormData(RmvEmptyProp(sellData)),
        null,
        onComplete
      );
    } else {
      if (sellData.sellItems.length <= 0) {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("noItemInCart"),
        });
      } else if (sellData.received === "") {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("paymentFieldRequired"),
        });
      } else if (receivedPay < totalPay) {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: t("wrongAmount"),
        });
      }
    }
  };

  useEffect(() => {
    setSellData({
      ...sellData,
      change: change,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change]);

  useEffect(() => {
    setChange((receivedPay - totalPay).toFixed(2));
    setSellData({
      ...sellData,
      sellItems: cartItems,
      paymentType: 1,
      subTotal: subTotal,
      taxPct: store.tax,
      tax: tax,
      totalPay: totalPay,
      received: receivedPay,
      userId: _authUser.id,
      storeId: store.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPay, receivedPay]);

  return (
    <>
      {/* Display */}
      <Grid className="tx-center gray-color mb-10 p-10">
        <Input
          fullWidth
          value={receivedPay}
          onChange={handleInpChange}
          className="bg-white rmv-arrow br-8"
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyRounded />
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "600",
              color: "var(--gray-color)",
            },
          }}
        />
        <Box className="mt-10">
          <Typography variant="subtitle1" className="fs-22 fw-6">
            {t("total")}:&nbsp;&nbsp; {org && org.curSymbol} {totalPay}
          </Typography>
          <Typography variant="subtitle1" className="fs-22 fw-6">
            {t("change")}:&nbsp;&nbsp; {org && org.curSymbol} {change}
          </Typography>
        </Box>
      </Grid>

      <Box
        className="d-flex"
        sx={{
          height: { xs: "calc(100vh - 400px)", md: "calc(100vh - 440px)" },
          flexWrap: { xs: "wrap", md: "inherit" },
        }}
      >
        <Box className="w-100pr d-grid" sx={{ mr: { md: "10px" } }}>
          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={() => handleNum(null, 5)}
              className="w-100pr fs-30 mr-10"
            >
              {org && org.curSymbol} 5
            </Button>
            <Button
              size="large"
              onClick={() => handleNum(null, 10)}
              className="w-100pr fs-30 mr-10"
            >
              {org && org.curSymbol} 10
            </Button>
            <Button
              size="large"
              onClick={() => handleNum(null, 20)}
              className="w-100pr fs-30 mr-10"
            >
              {org && org.curSymbol} 20
            </Button>
            <Button
              size="large"
              onClick={() => handleNum(null, 50)}
              className="w-100pr fs-30"
            >
              {org && org.curSymbol} 50
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              1
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              2
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              3
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30"
            >
              4
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              5
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              6
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              7
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30"
            >
              8
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              9
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              0
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              .
            </Button>
            <Button
              size="large"
              onClick={clear}
              className="w-100pr bg-white gray-color"
              sx={{ fontSize: { xs: "16px", md: "22px" } }}
            >
              {t("clear")}
            </Button>
          </Box>
        </Box>

        <Box
          className="mb-10"
          sx={{
            width: { xs: "100%", md: "220px" },
            display: { xs: "flex", md: "grid" },
          }}
        >
          <Button
            size="large"
            className="w-100pr bg-white gray-color fs-20"
            sx={{ mb: { md: "10px" }, mr: { xs: "10px" } }}
          >
            {t("credit")}
          </Button>
          <Button
            size="large"
            onClick={() => navigate("/pos")}
            className="w-100pr bg-white gray-color fs-20"
            sx={{ mb: { md: "10px" }, mr: { xs: "10px" } }}
          >
            {t("cancel")}
          </Button>
          <Button
            loading={loading}
            size="large"
            onClick={handleSubmit}
            className="w-100pr bg-error white-color fs-26 d-block"
          >
            {"pay"} <br /> {t("now")}
            {loading && "..."}
          </Button>
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(Checkout);
