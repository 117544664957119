import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProfitBreakdown = ({ sellsItem, expenses }) => {
  const { t } = useTranslation();

  const [sellItems, setSellItems] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [purchPrice, setPurchPrice] = useState(0);
  const [totalExp, setTotalExp] = useState(0);

  useEffect(() => {
    const monthlySells = sellsItem.filter(
      (j) =>
        moment(j.sellDate, "MM/DD/yyyy").format("MM/yyyy") ===
        moment().format("MM/yyyy")
    );

    setSellItems(monthlySells.map((j) => j.qty).reduce((x, y) => x + y, 0));
    setSellPrice(
      monthlySells
        .map((j) => Math.round(j.qty * j.price))
        .reduce((x, y) => x + y, 0)
    );
    setPurchPrice(
      monthlySells
        .filter((j) => j.itemType === 1)
        .map((j) => Math.round(j.qty * j.product.purchPrice))
        .reduce((x, y) => x + y, 0)
    );
    setTotalExp(
      expenses
        .filter(
          (j) =>
            moment(j.addDate, "MM/DD/yyyy").format("MM/yyyy") ===
            moment().format("MM/yyyy")
        )
        .map((j) => Math.round(j.amount))
        .reduce((x, y) => x + y, 0)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellsItem]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    grid: {
      padding: {
        bottom: -90,
      },
    },
    labels: [
      t("numberOfItemSold"),
      t("sellPrice"),
      t("purchasePrice"),
      t("totalExpense"),
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontSize: "16px",
      fontWeight: "600",
      itemMargin: {
        horizontal: 10,
        vertical: 8,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const series = [
    sellItems,
    parseInt(sellPrice),
    parseInt(purchPrice),
    parseInt(totalExp),
  ];

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-10">
        {t("profitBreakdown")}
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

export default ProfitBreakdown;
