import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Input, InputAuto } from "../../controls";
import { Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { endpoints } from "../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { useTranslation } from "react-i18next";

const AddEditCat = (props) => {
  const { updData, setShowForm, _post, _put, _get } = props;
  const { t } = useTranslation();

  const { CatMdl } = Model();
  const [parentCats, setParentCats] = useState([]);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(CatMdl);

  const validateForm = () => {
    let temp = {};
    temp.name = values.name !== "" ? "" : t("categoryNameIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  useEffect(() => {
    _get(endpoints.Category, null, (res) =>
      setParentCats([
        ...res.result
          .filter((j) => !j.parentCatId)
          .map((j) => ({ ...j, label: j.name })),
      ])
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const onComplete = () => {
        setShowForm(false);
      };
      if (updData === undefined) {
        _post(
          endpoints.Category,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.AddItem,
          onComplete
        );
      } else if (updData !== undefined && updData.id !== undefined) {
        _put(
          endpoints.Category,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.UpdateItem,
          onComplete
        );
      }
    }
  };

  useEffect(() => {
    updData && setValues((j) => ({ ...j, ...updData }));
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updData]);

  return (
    <div className="bg-grayXS">
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} className="p-20 pt-40">
          <Grid item xs={12} className="tx-center">
            <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
              {updData === undefined ? t("addNew") : t("update")}{" "}
              {t("category")}
            </Typography>
            <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("categoryName")}
              name="name"
              value={values.name}
              error={errors.name}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={t("productDescription")}
              multiline
              rows={4}
              name="descr"
              value={values.descr}
              error={errors.descr}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <InputAuto
              label={t("parentCategory")}
              name="parentCatId"
              fullWidth
              value={values.parentCatId}
              error={errors.parentCatId}
              onChange={handleInpChg}
              options={parentCats}
            />
          </Grid>

          <Grid
            item
            xs={12}
            className="tx-right d-flex mt-20"
            sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
          >
            <Button type="submit" className="w-100pr" size="large">
              {updData === undefined ? t("submit") : t("saveChanges")}
            </Button>
            <Button
              onClick={() => setShowForm(false)}
              className="error-color bg-errorSM w-100pr"
              size="large"
              sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
            >
              {t("close")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditCat);
