import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { Button } from "../../controls";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const ViewRegister = ({ setShowForm, viewData }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  return (
    <Grid container sx={{ padding: { xs: "10px", md: "20px" } }}>
      <Grid item xs={12} className="tx-center mt-20">
        <Typography variant="h6" sx={{ fontSize: { md: "20px", xl: "26px" } }}>
          {t("registerDetails")}
        </Typography>
        <div className="bs-bottom mx-w-150 m-auto mt-10 mb-30" />
      </Grid>

      <Grid item xs={12}>
        <Box
          className="d-flex j-between"
          sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
        >
          {/* Cashier Details */}
          <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                borderLeft: "0.4rem double var(--primary-color)",
                paddingLeft: "10px",
              }}
            >
              {t("cashierDetails")}
            </Typography>
            <Divider className="pt-10 mb-5" />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "140px", md: "180px" } }}
              >
                {t("storeName")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.store.storeName}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "140px", md: "180px" } }}
              >
                {t("address")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.store.address}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "140px", md: "180px" } }}
              >
                {t("cashierName")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.user.firstName + " " + viewData.user.lastName}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "140px", md: "180px" } }}
              >
                {t("saleDate")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.date}</Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "140px", md: "180px" } }}
              >
                {t("timeIn")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.openTime}</Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "140px", md: "180px" } }}
              >
                {t("timeOut")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.closeTime}</Typography>
            </Box>
            <Divider />
          </Box>
          {/* Register Details */}
          <Box className="mt-20 mb-30 w-100pr">
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                borderLeft: "0.4rem double var(--primary-color)",
                paddingLeft: "10px",
              }}
            >
              {t("registerDetails")}
            </Typography>
            <Divider className="pt-10 mb-5" />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalCustomers")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.totalCstmr}</Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("averagePerCustomer")}:
              </Typography>
              <Typography variant="subtitle1">
                {org && org.curSymbol} {viewData.averagePerCstmr}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalSaleRefund")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.totalRefund}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalVoidSells")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.totalVoidSale}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalItemCanceled")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.itemCancel}</Typography>
            </Box>
            <Divider />
          </Box>
        </Box>

        <Box
          className="d-flex j-between"
          sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
        >
          {/* Sale Report */}
          <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                borderLeft: "0.4rem double var(--primary-color)",
                paddingLeft: "10px",
              }}
            >
              {t("saleReport")}
            </Typography>
            <Divider className="pt-10 mb-5" />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalSale")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.totalSale}</Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalCashSale")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.totalCash}</Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalCreditSale")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.totalCradit}
              </Typography>
            </Box>
            <Divider />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("otherPaymentReceived")}:
              </Typography>
              <Typography variant="subtitle1">
                {" "}
                {org && org.curSymbol} {viewData.otherPayment}
              </Typography>
            </Box>
          </Box>
          {/* Sell Details */}
          <Box className="mt-20 mb-30 w-100pr">
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                borderLeft: "0.4rem double var(--primary-color)",
                paddingLeft: "10px",
              }}
            >
              {t("sellDetails")}
            </Typography>
            <Divider className="pt-10 mb-5" />

            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalGrossSale")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.totalSale}</Typography>
            </Box>
            <Divider />

            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalTaxItemSold")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.taxItemSold}
              </Typography>
            </Box>

            <Divider />

            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalNonTaxItemSold")}:
              </Typography>
              <Typography variant="subtitle1">
                {viewData.nonTaxItemSold}
              </Typography>
            </Box>

            <Divider />

            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "180px", md: "200px" } }}
              >
                {t("totalTaxReceived")}:
              </Typography>
              <Typography variant="subtitle1">
                {org && org.curSymbol} {viewData.taxReceived}
              </Typography>
            </Box>
          </Box>
          <Divider />
        </Box>
        <Box
          className="d-flex j-between"
          sx={{ flexWrap: { xs: "wrap", md: "inherit" } }}
        >
          {/* Money */}
          <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                borderLeft: "0.4rem double var(--primary-color)",
                paddingLeft: "10px",
              }}
            >
              {t("money")}
            </Typography>
            <Divider className="pt-10 mb-5" />
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "220px", md: "250px" } }}
              >
                {t("totalCashSale")}:
              </Typography>
              <Typography variant="subtitle1">{viewData.totalCash}</Typography>
            </Box>
            <Box className="d-flex">
              <Typography
                variant="subtitle1"
                className="fw-6"
                sx={{ minWidth: { xs: "220px", md: "250px" } }}
              >
                {t("shiftTransactionsBalance")}:
              </Typography>
              <Typography variant="subtitle1">
                {org && org.curSymbol} {viewData.shiftTransBalanace}
              </Typography>
            </Box>
            <Divider />
          </Box>

          {/* Notes */}
          <Box className="mt-20 mb-30 w-100pr" sx={{ mr: { md: "20px" } }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: "16px", md: "18px" },
                borderLeft: "0.4rem double var(--primary-color)",
                paddingLeft: "10px",
              }}
            >
              {t("notes")}
            </Typography>
            <Divider className="pt-10 mb-5" />
            <Typography variant="subtitle1">{viewData.notes}</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} className="tx-center mt-40">
        <Button
          onClick={() => setShowForm(false)}
          className="btn-error"
          size="large"
          sx={{ minWidth: "150px" }}
        >
          {t("close")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ViewRegister;
