import React, { useState } from "react";
import { connect } from "react-redux";
import { Alert, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { DropLi } from "../../hooks";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const EmployeeHistory = (props) => {
  const { _clear, _get, _list, _type } = props;
  const { t } = useTranslation();
  const { Periodic } = DropLi();
  const { setLoader } = useContext(AppContext);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    setLoader(true);
    _get(
      endpoints.User + controller.GetAllEmployees,
      actions.ActionType.List,
      () => setLoader(false)
    );
    return () => {
      _clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "firstName", label: t("firstName") },
          { id: "lastName", label: t("lastName") },
          { id: "dob", label: t("dateOfBirth") },
          { id: "salaryType", label: t("salaryType") },
          { id: "salary", label: t("salary") },
          { id: "addDate", label: t("hireDate") },
        ]}
        tblData={
          _type === "employees"
            ? _list.map((obj) => {
                return {
                  ...obj,
                  salaryType:
                    obj.salaryType &&
                    Periodic.find((j) => j.id === obj.salaryType).name,
                };
              })
            : []
        }
      />
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
});

const mapActionToProps = {
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(EmployeeHistory);
