import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Button } from "../../controls";
import { useTranslation } from "react-i18next";

const ViewDetails = (props) => {
  const { viewData, setShowForm } = props;
  const { t } = useTranslation();

  return (
    <Grid container className="p-20">
      <Grid item xs={12} className="tx-center mt-20">
        <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp mb-10">
          '{viewData.product.name}' {t("addedDetails")}
        </Typography>
        <div className="bs-bottom mx-w-80 m-auto mb-30" />
      </Grid>
      <Grid item xs={12}>
        <TableContainer className="mt-20">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>{t("userName")}</TableCell>
                <TableCell>{t("inventoryAdded")}</TableCell>
                <TableCell>{t("date")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData.invUsers
                .sort((a, b) => b.id - a.id)
                .map((inv, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {inv.user.firstName + " " + inv.user.lastName}
                    </TableCell>
                    <TableCell>{inv.addedInv}</TableCell>
                    <TableCell>{inv.date}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} className="tx-center mt-40">
        <Button
          onClick={() => setShowForm(false)}
          className="btn-error"
          size="large"
          sx={{ minWidth: "150px" }}
        >
          {t("close")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ViewDetails;
