import React from "react";
import { Button, Grid, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../controls";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { endpoints } from "../../api/actions/api";
import { NestedFormData, RmvEmptyProp } from "../../hooks/Method";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const Refund = (props) => {
  const {
    cartItems,
    tax,
    subTotal,
    setAlert,
    handleClearCart,
    _post,
    _authUser,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const { setLoader, selStore } = useContext(AppContext);

  const handleRefund = () => {
    if (val === "") {
      setAlert({
        isOpen: true,
        type: "error",
        title: t("error"),
        subTitle: t("refundInvIdReq"),
      });
      return;
    }

    const refundVal = {
      id: val,
      items: cartItems.map((j) =>
        j.itemType === 2 ? { ...j, productId: "" } : j
      ),
      tax: tax,
      cancelType: 2,
      subtotal: subTotal,
      userId: _authUser.id,
      storeId: selStore.id,
    };
    setLoader(true);
    const onComplete = (res) => {
      if (res.status === 201) {
        setAlert({
          isOpen: true,
          type: "success",
          title: t("successful"),
          subTitle: t("youHaveSuccessfullyRefund"),
        });
        handleClearCart();
        navigate("/pos");
      } else {
        setAlert({
          isOpen: true,
          type: "warning",
          title: t("warning"),
          subTitle: res.msg,
        });
      }
      setLoader(false);
    };
    _post(
      endpoints.VoidSell,
      NestedFormData(RmvEmptyProp(refundVal)),
      null,
      onComplete
    );
  };

  const handleInpChange = (e) => {
    setVal(e.target.value);
  };

  const handleNum = (e, val) => {
    setVal((x) => x + (val !== undefined ? val : e.target.innerText));
  };

  const clear = () => {
    setVal("");
  };

  useEffect(() => {
    return () => {
      setVal("");
    };
  }, []);

  return (
    <>
      {/* Display */}
      <Grid className="gray-color mb-20 p-5">
        <Input
          value={val}
          onChange={handleInpChange}
          type="number"
          variant="outlined"
          className="bg-white rmv-arrow br-8 w-100pr"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography className="fw-6 fs-20">
                  {t("invoiceID")}:
                </Typography>{" "}
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "28px",
              fontWeight: "600",
              color: "var(--gray-color)",
            },
          }}
        />
      </Grid>

      <Box
        className="d-flex"
        sx={{
          height: { xs: "calc(100vh - 400px)", md: "calc(100vh - 440px)" },
          flexWrap: { xs: "wrap", md: "inherit" },
        }}
      >
        <Box className="w-100pr d-grid" sx={{ mr: { md: "10px" } }}>
          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              1
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              2
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              3
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30"
            >
              4
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              5
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              6
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              7
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30"
            >
              8
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              9
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              0
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              .
            </Button>
            <Button
              size="large"
              onClick={clear}
              className="w-100pr bg-white gray-color"
              sx={{ fontSize: { xs: "16px", md: "22px" } }}
            >
              {t("clear")}
            </Button>
          </Box>
        </Box>

        <Box
          className="mb-10"
          sx={{
            width: { xs: "100%", md: "220px" },
            display: { xs: "flex", md: "grid" },
          }}
        >
          <Button
            size="large"
            onClick={() => navigate("/pos")}
            className="bg-white gray-color fs-18"
            sx={{
              mb: { xs: "0px", md: "10px" },
              mr: { xs: "10px", md: "0px" },
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            size="large"
            onClick={handleRefund}
            className="w-100pr bg-error white-color fs-26"
          >
            {t("refundNow")}
          </Button>
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
};

export default connect(mapStateToProps, mapActionToProps)(Refund);
