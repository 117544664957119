import { Grid } from "@mui/material";
import React from "react";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import {
  SalesAccWidget,
  SalesChart,
  PeriodicalSalesReports,
  PurchaseAndSellChart,
  StoreValueReport,
  TopTenItems,
  SalesByEmployee,
  ProfitBreakdown,
  TopSellingProdTable,
  StoreExpenses,
} from "../index";
import { useContext } from "react";
import { AppContext } from "../../App";
import { useState } from "react";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import moment from "moment";
import { GetMonths } from "../../hooks/Method";

const Home = (props) => {
  const { _getById, _authUser } = props;
  const { setLoader, selStore } = useContext(AppContext);
  const [sellsPerMonth, setSellsPerMonth] = useState([]);
  const [purchPerMonth, setPurchPerMonth] = useState([]);
  const [sellsItem, setSellsItem] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [sells, setSells] = useState([]);
  const [yearlySells, setYearlySells] = useState(0);
  const [montlySells, setMontlySells] = useState(0);
  const [weeklySells, setWeeklySells] = useState(0);
  const [dailySells, setDailySells] = useState(0);
  const [currentInvVal, setCurrentInvVal] = useState(0);
  const [products, setProducts] = useState([]);
  const [inventories, setInventories] = useState([]);

  //Periodical Sales && Sort Product Sells
  useEffect(() => {
    const _sells = sells
      .map((j) =>
        j.sellItems.map((y) => {
          return {
            ...y,
            sellDate: j.sellDate,
            userId: j.userId,
            user: j.user,
          };
        })
      )
      .flat();
    setSellsItem(_sells);

    const _yearlySells = _sells
      .filter(
        (j) => new Date(j.sellDate).getFullYear() === new Date().getFullYear()
      )
      .map((j) => j.price * j.qty)
      .reduce((x, y) => x + y, 0);
    setYearlySells(_yearlySells.toLocaleString());

    const _monthlySells = _sells
      .filter(
        (j) =>
          moment(j.sellDate, "MM/DD/yyyy").format("MM/yyyy") ===
          moment().format("MM/yyyy")
      )
      .map((j) => j.price * j.qty)
      .reduce((x, y) => x + y, 0);
    setMontlySells(_monthlySells.toLocaleString());

    const _weeklySells = _sells
      .filter(
        (j) =>
          moment(j.sellDate, "MM/DD/yyyy").format("MM/DD/yyyy") >=
          moment().startOf("week").format("MM/DD/yyyy")
      )
      .map((j) => j.price * j.qty)
      .reduce((x, y) => x + y, 0);
    setWeeklySells(_weeklySells.toLocaleString());

    const _dailySells = _sells
      .filter(
        (j) =>
          moment(j.sellDate, "MM/DD/yyyy").format("MM/DD/yyyy") ===
          moment().format("MM/DD/yyyy")
      )
      .map((j) => j.price * j.qty)
      .reduce((x, y) => x + y, 0);
    setDailySells(_dailySells.toLocaleString());

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sells]);

  const onComplete = (res) => {
    res.status === 200 && setSells(res.result);
    setLoader(false);
  };

  const invOnComplete = (res) => {
    if (res.status === 200) {
      setInventories(res.result);
      setCurrentInvVal(
        res.result
          .map((j) => j.product.purchPrice * j.inInventory)
          .reduce((x, y) => x + y, 0)
          .toLocaleString()
      );
    }
  };

  // Get Datas from the server
  useEffect(() => {
    setTimeout(() => {
      if (selStore !== undefined && _authUser.role === 1) {
        setLoader(true);
        // Get Sell By Store Id List
        _getById(
          endpoints.Sell + controller.GetSellsByStore,
          selStore,
          null,
          onComplete
        );

        // Get Purchases
        _getById(
          endpoints.Purchase + controller.GetByStore,
          selStore,
          null,
          (res) => res.status === 200 && setPurchases(res.result)
        );

        // Get In Inventory Values
        _getById(
          endpoints.Inventory + controller.GetProdsByStore,
          selStore,
          null,
          invOnComplete
        );

        // Get Expenses
        _getById(
          endpoints.Expense + controller.GetByStoreId,
          selStore,
          null,
          (res) => res.status === 200 && setExpenses(res.result)
        );
      }
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  // Get sells per month
  useEffect(() => {
    const _sellsPerMonth = GetMonths().map((j) =>
      sellsItem.filter(
        (x) => moment(x.sellDate, "MM/DD/yyyy").format("MM/yyyy") === j
      )
    );
    setSellsPerMonth(
      _sellsPerMonth.map((j) =>
        j
          .map((j) => j.price * j.qty)
          .reduce((x, y) => x + y, 0)
          .toFixed(2)
      )
    );

    // Sort Product By Sells
    const filteredProds = [];
    const _prods = sellsItem.filter((j) => j.itemType === 1);
    _prods.forEach((j) => {
      var prod = _prods.filter((x) => x.product.id === j.product.id);
      // Check the product list is exist in the list before push to the list.
      !filteredProds.some((x) => JSON.stringify(x) === JSON.stringify(prod)) &&
        filteredProds.push(prod);
    });
    setProducts(filteredProds);
  }, [sellsItem]);

  // Get purchase per month
  useEffect(() => {
    const _purchPerMonth = GetMonths().map((j) =>
      purchases.filter(
        (x) => moment(x.purchDate, "MM/DD/yyyy").format("MM/yyyy") === j
      )
    );
    setPurchPerMonth(
      _purchPerMonth.map((j) =>
        j
          .map((j) =>
            j.purchItems
              .map((j) => j.qty * j.purchPrice)
              .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
      )
    );
  }, [purchases]);

  return (
    <Grid container spacing={3} className="tx-upp">
      <Grid item xs={12}>
        <PeriodicalSalesReports
          {...{ yearlySells, montlySells, weeklySells, dailySells }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <SalesAccWidget {...{ sellsItem, expenses, purchases }} />
      </Grid>
      <Grid item xs={12} md={7}>
        <SalesChart {...{ sellsPerMonth }} />
      </Grid>
      <Grid item xs={12} md={9}>
        <PurchaseAndSellChart {...{ sellsPerMonth, purchPerMonth }} />
      </Grid>
      <Grid item xs={12} md={3}>
        <StoreValueReport {...{ currentInvVal }} />
      </Grid>
      <Grid item xs={12}>
        <TopTenItems {...{ products }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <SalesByEmployee {...{ sellsItem }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <ProfitBreakdown {...{ sellsItem, expenses }} />
      </Grid>
      <Grid item xs={12} md={4}>
        <StoreExpenses {...{ expenses }} />
      </Grid>
      <Grid item xs={12}>
        <TopSellingProdTable {...{ sellsItem, inventories }} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(Home);
