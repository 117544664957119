import React, { useState } from "react";
import { Input, Grow, Typography, IconButton, Box } from "@mui/material";
import { Alert } from "../layout";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { useEffect } from "react";

export default function InputImg(props) {
  const {
    label = "Upload Images",
    values = [],
    name,
    error = null,
    onChange,
    rmv,
    src,
    files,
    maxUpl = 5,
    maxSize = 3,
    maxImgWidth = "300px",
    defImg = process.env.PUBLIC_URL + "/assets/img/app/add-img.png",
    ...other
  } = props;

  const [imgsSrc, setImgsSrc] = useState([]);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  const showPreview = (e) => {
    const imgs = e.target.files;
    const uplImgs = [];
    if (imgsSrc.length + imgs.length <= maxUpl) {
      Array.from(imgs).forEach((file) => {
        if (file.size / 1048576 < maxSize) {
          setImgsSrc((x) => [
            ...x,
            {
              lastModified: file.lastModified,
              size: file.size,
              src: URL.createObjectURL(file),
            },
          ]);
          uplImgs.push(file);
        } else {
          setAlert({
            isOpen: true,
            type: "warning",
            title: `Maximum Allowed ${maxSize}MB`,
            subTitle: `"${file.name}" size is ${(file.size / 1048576).toFixed(
              2
            )}MB. You can't upload images larger than ${maxSize}MB.`,
          });
        }
      });
      // return images
      onChange && onChange({ name, value: values.concat(uplImgs) });
    } else {
      setAlert({
        isOpen: true,
        type: "warning",
        title: `Maximum Upload ${maxUpl} Images`,
        subTitle: `You have uploaded ${
          imgsSrc.length
        } images. Now you can upload ${maxUpl - imgsSrc.length} images`,
      });
    }
    // FOR BUG IN CHROME
    e.target.value = "";
  };

  const rmvImg = (img) => {
    setImgsSrc(imgsSrc.filter((e) => e !== img));
    URL.revokeObjectURL(img);
    rmv && rmv(img);
  };

  useEffect(() => {
    src && setImgsSrc(src);
    return () => {
      setImgsSrc(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  useEffect(() => {
    if (values.length > 0 && imgsSrc.length === 0) {
      Array.from(values).forEach((file) => {
        setImgsSrc((x) => [
          ...x,
          {
            lastModified: file.lastModified,
            size: file.size,
            src: URL.createObjectURL(file),
          },
        ]);
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box {...other}>
        <div className="d-flex flex-wrap g-10">
          {imgsSrc.map((img, i) => (
            <div key={i}>
              <Grow in timeout={1000}>
                <div className="pos-relative">
                  <img
                    className="w-100pr obj-cover c-pointer br-8"
                    src={img.src}
                    alt={img.src}
                    style={{
                      height: "inherit",
                      maxWidth: maxImgWidth,
                    }}
                  />
                  <IconButton
                    color="error"
                    size="small"
                    onClick={() => rmvImg(img)}
                    sx={{ margin: "10px", right: "0", position: "absolute" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </Grow>
            </div>
          ))}
          {/* Upload Image */}
          {imgsSrc.length < maxUpl && (
            <div className="pos-relative">
              <label
                htmlFor={name}
                className="w-100pr d-flex j-center bg-primaryXS br-10"
              >
                <FileUploadRoundedIcon
                  className="obj-cover c-pointer"
                  color="whitemd"
                  sx={{
                    height: "100%",
                    width: "100%",
                    maxWidth: maxImgWidth,
                  }}
                  src={defImg}
                  alt="Attach a PP Pic"
                />
                <Input
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  className="d-none"
                  name={name}
                  id={name}
                  onChange={showPreview}
                  type="file"
                  inputProps={{ multiple: true }}
                />
                <Typography
                  variant="span"
                  className="pos-absolute bottom-0 p-10 tx-upp gray-color c-pointer"
                >
                  {label}
                </Typography>
              </label>
            </div>
          )}
        </div>
      </Box>
      <Typography color="error" className="mt-10 font-4">
        {error}
      </Typography>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
}
