import axios from "axios";

const BaseUrl = "https://posb.aashirwadbangles.com/api/";

export const endpoints = {
  Store: "Store",
  User: "User",
  Product: "Product",
  Purchase: "Purchase",
  Supplier: "Supplier",
  ExpenseCat: "ExpenseCat",
  Expense: "Expense",
  Auth: "Auth",
  PurchReturn: "PurchReturn",
  Category: "Category",
  Inventory: "Inventory",
  Salary: "Salary",
  StockTrans: "StockTrans",
  PosItem: "PosItem",
  Sell: "Sell",
  VoidSell: "VoidSell",
  Register: "Register",
  Org: "Org",
};

export const controller = {
  GetById: "/GetById",
  GetAuthUser: "/GetAuthUser",
  CheckUserName: "/CheckUserName",
  Login: "/Login",
  Logout: "/Logout",
  Forgot: "/Forgot",
  SetDefStore: "/SetDefStore",
  GetDefStore: "/GetDefStore",
  GetProductByBarcode: "/GetProductByBarcode",
  GetAllEmployees: "/GetAllEmployees",
  GetProdsByStore: "/GetProdsByStore",
  FindProducts: "/FindProducts",
  FindCategories: "/FindCategories",
  GetByStoreId: "/GetByStoreId",
  GetByStore: "/GetByStore",
  GetByBarcode: "/GetByBarcode",
  GetProdsByCat: "/GetProdsByCat",
  GetLastSell: "/GetLastSell",
  GetSellsByStore: "/GetSellsByStore",
  GetCatsByStore: "/GetCatsByStore",
  CloseRegSells: "/CloseRegSells",
  PostCancelCart: "/PostCancelCart",
  StoreReport: "/StoreReport",
  GetRegsByStore: "/GetRegsByStore",
  GetCancelCartsByStore: "/GetCancelCartsByStore",
  AvailableInStock: "/AvailableInStock",
  AuthUserStores: "/AuthUserStores",
  ImportExcel: "/ImportExcel",
};

export const api = (endpoint) => {
  const url = BaseUrl + endpoint + "/";
  return {
    get: () => axios.get(url, { withCredentials: true }),
    getById: (id) => axios.get(url + id, { withCredentials: true }),
    getByObj: (obj) => axios.post(url, obj, { withCredentials: true }),
    post: (data) => axios.post(url, data, { withCredentials: true }),
    put: (data) => axios.put(url, data, { withCredentials: true }),
    delete: (id) => axios.delete(url + id, { withCredentials: true }),
  };
};
