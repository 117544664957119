import { Button, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Input, Select } from "../../controls";
import { Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { useState } from "react";
import { endpoints } from "../../api/actions/api";
import { RmvEmptyProp, ToFormData } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const ExpenseForm = (props) => {
  const { setShowForm, _post, _get, _authUser } = props;
  const { t } = useTranslation();
  const { ExpsMdl } = Model();
  const { values, errors, setErrors, handleInpChg, resetFormControls } =
    UseForm(ExpsMdl);
  const { selStore } = useContext(AppContext);
  const [expenseCats, setExpenseCats] = useState([]);

  const validateForm = () => {
    let temp = {};
    temp.amount = values.amount !== "" ? "" : t("amountIsRequired");
    temp.expenseCatId =
      values.expenseCatId !== "" ? "" : t("expenseTypeIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      values.userId = _authUser.id;
      values.storeId = selStore;

      _post(endpoints.Expense, ToFormData(RmvEmptyProp(values)), null, () =>
        setShowForm(false)
      );
    }
  };

  useEffect(() => {
    const onComplete = (res) => {
      res.status === 200 &&
        res.result.length > 0 &&
        setExpenseCats(
          res.result
            .sort((f, s) => f.id - s.id)
            .map((j) => {
              return {
                id: j.id,
                label: j.name,
              };
            })
        );
    };
    _get(endpoints.ExpenseCat, null, onComplete);
    return () => {
      resetFormControls();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={3} className="p-15 pt-40 bg-grayXS">
        <Grid item xs={12} className="tx-center">
          <Typography variant="h5" className="fs-22 gray-color fw-5 tx-upp">
            {t("addExpense")}
          </Typography>
          <div className="bs-bottom mx-w-80 m-auto mt-10 mb-30" />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={t("amount")}
            name="amount"
            value={values.amount}
            error={errors.amount}
            type="number"
            onChange={handleInpChg}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            label={t("expenseType")}
            name="expenseCatId"
            fullWidth
            value={values.expenseCatId}
            error={errors.expenseCatId}
            onChange={handleInpChg}
            options={expenseCats}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className="tx-right d-flex mt-10"
          sx={{ flexWrap: { xs: "wrap", md: "initial" } }}
        >
          <Button type="submit" className="w-100pr" size="large">
            {t("submit")}
          </Button>
          <Button
            onClick={() => setShowForm(false)}
            className="error-color bg-errorSM w-100pr"
            size="large"
            sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
          >
            {t("close")}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(ExpenseForm);
