import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const LabelComp = ({ label, value }) => {
  return (
    <Box
      className="gray-color p-5 pl-10"
      sx={{
        bgcolor: "var(--white-color)",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottom: "1px solid var(--grayMD-color)",
        pl: "12px",
        pr: "12px",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          width: "fit-content",
          fontSize: "12px",
          fontStyle: "italic",
        }}
      >
        {value !== undefined && value !== "" && label} (Read Only)
      </Typography>
      <Typography variant="subtitle1">{value || label} &nbsp;</Typography>
    </Box>
  );
};

export default LabelComp;
