import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { AppContext } from "../../App";
import { Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useTranslation } from "react-i18next";

const ExpenseReport = (props) => {
  const { _getById } = props;
  const { setLoader, selStore, org } = useContext(AppContext);
  const [expenses, setExpenses] = useState([]);
  const { t } = useTranslation();

  const onComplete = (res) => {
    setLoader(false);
    res.status === 200 && setExpenses(res.result);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.Expense + controller.GetByStoreId,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "addDate", label: t("date") },
          { id: "expenseType", label: t("expenseType") },
          { id: "note", label: t("detailsNote") },
          { id: "amount", label: t("paid") },
          { id: "paidBy", label: t("paidBy") },
        ]}
        tblData={expenses.map((exp) => {
          return {
            ...exp,
            expenseType: exp.expenseCat.name,
            amount: (org && org.curSymbol) + " " + exp.amount,
            paidBy: exp.user.firstName + " " + exp.user.lastName,
          };
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ExpenseReport);
