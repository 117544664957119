import { Button as MuiButton, CircularProgress } from "@mui/material";

export default function Button(props) {
  const {
    name,
    loading = false,
    onClick,
    type = "button",
    colorLdr = "primary",
    children,
    variant,
    ...other
  } = props;
  return (
    <MuiButton
      startIcon={
        loading && <CircularProgress color={colorLdr} className="h-25 w-25" />
      }
      disabled={loading}
      variant={variant || "contained"}
      type={type}
      onClick={onClick}
      disableElevation
      {...other}
    >
      {children}
    </MuiButton>
  );
}
