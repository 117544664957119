import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { DropLi } from "../../hooks";
import { IsEmptyObj } from "../../hooks/Method";
import { CloseRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const ViewPurch = (props) => {
  const { org } = useContext(AppContext);
  const { setShowForm, viewData } = props;
  const { t } = useTranslation();
  const { PaymentType } = DropLi();
  const [total, setTotal] = useState(0);
  const [discountA, setDiscountA] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    if (!IsEmptyObj(viewData)) {
      var netAmount = viewData.purchItems
        .map((j) => j.qty * j.purchPrice)
        .reduce((x, y) => x + y, 0)
        .toFixed(2);
      setSubtotal(netAmount);
      var discount = (
        (parseFloat(viewData.discount || 0) / 100) *
        netAmount
      ).toFixed(2);
      setDiscountA(discount);
      setTotal(netAmount - discount);
    }
  }, [viewData]);

  return (
    <div>
      <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
        <div>
          <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
            {t("purchaseDetails")}
          </h1>
        </div>

        <div>
          <IconButton onClick={() => setShowForm(false)}>
            <CloseRounded />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Grid container>
        <Grid item xs={12}>
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("itemName")}</TableCell>
                  <TableCell>{t("barcode")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("price")}</TableCell>
                  <TableCell>{t("total")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewData.purchItems.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.product && item.product.name}</TableCell>
                    <TableCell>
                      {item.product && item.product.barcode}
                    </TableCell>
                    <TableCell>{item.qty}</TableCell>
                    <TableCell>
                      {(org && org.curSymbol) + " " + item.purchPrice}
                    </TableCell>
                    <TableCell>
                      {(org && org.curSymbol) +
                        " " +
                        item.qty * item.purchPrice}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Divider />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className="w-100pr"
          sx={{
            borderRight: { sm: "1px solid var(--grayLight-color)" },
          }}
        >
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("referenceNo")} :</TableCell>
                  <TableCell align="right">{viewData.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("supplier")} :</TableCell>
                  <TableCell align="right">{viewData.supplier}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("purchaseUser")} :</TableCell>
                  <TableCell align="right">
                    {viewData.user.firstName + " " + viewData.user.lastName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("paymentType")}: </TableCell>
                  <TableCell align="right">
                    {" "}
                    {viewData.paymentType &&
                      PaymentType.find((j) => j.id === viewData.paymentType)
                        .name}
                  </TableCell>
                </TableRow>
                {viewData.paymentDue && (
                  <TableRow>
                    <TableCell>{t("paymentDue")} :</TableCell>
                    <TableCell align="right">
                      {(org && org.curSymbol) +
                        " " +
                        (viewData.paymentDue || 0)}
                    </TableCell>
                  </TableRow>
                )}
                {viewData.recept && (
                  <TableRow>
                    <TableCell>{t("purchaseRecept")} :</TableCell>
                    <TableCell align="right">
                      <a
                        href={viewData.recept}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("viewRecept")}
                      </a>
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} className="w-100pr">
          <TableContainer>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("purchaseDate")}: </TableCell>
                  <TableCell align="right">{viewData.purchDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("totalItem")}:</TableCell>
                  <TableCell align="right">
                    {viewData.purchItems
                      .map((j) => j.qty)
                      .reduce((x, y) => x + y, 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("subtotal")}: </TableCell>
                  <TableCell align="right">
                    {org && org.curSymbol + " " + subtotal}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("discount")}: </TableCell>
                  <TableCell align="right">
                    {viewData.discount || 0}% - ({org && org.curSymbol + " "}{" "}
                    {discountA})
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("total")}: </TableCell>
                  <TableCell align="right">
                    {" "}
                    {org && org.curSymbol + " " + total}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewPurch;
