import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import { useTranslation } from "react-i18next";

const EmployeeReport = (props) => {
  const { _getById } = props;
  const { t } = useTranslation();
  const { setLoader, selStore, org } = useContext(AppContext);
  const [sells, setSells] = useState([]);
  const [voidSells, setVoidSells] = useState([]);
  const [cancelItems, setCancelItems] = useState([]);

  const onComplete = (res) => {
    if (res.status === 200 && res.result.length > 0) {
      const filteredProds = [];
      const _sells = res.result
        .map((j) =>
          j.sellItems.map((y) => {
            return {
              ...y,
              sellDate: j.sellDate,
              userId: j.userId,
              user: j.user,
            };
          })
        )
        .flat();

      // Push Product
      _sells.forEach((j) => {
        var prod = _sells.filter((x) => x.userId === j.userId);
        // Check the product list is exist in the list before push to the list.
        !filteredProds.some(
          (x) => JSON.stringify(x) === JSON.stringify(prod)
        ) && filteredProds.push(prod);
      });
      setSells(filteredProds);
      setLoader(false);
    } else setLoader(false);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      // Get Sell List
      _getById(
        endpoints.Sell + controller.GetSellsByStore,
        selStore,
        null,
        onComplete
      );
      // Get Void List
      _getById(
        endpoints.VoidSell + controller.GetByStore,
        selStore,
        null,
        (res) => res.status === 200 && setVoidSells(res.result)
      );
      // Get Cancel List
      _getById(
        endpoints.Sell + controller.GetCancelCartsByStore,
        selStore,
        null,
        (res) => res.status === 200 && setCancelItems(res.result)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "emplName", label: t("employeeName") },
          { id: "totalSale", label: t("totalSale") },
          { id: "voidSale", label: t("voidSale") },
          { id: "refund", label: t("refund") },
          { id: "itemCancel", label: t("itemCancel") },
        ]}
        tblData={sells.map((repo) => {
          const voidSale = voidSells
            .filter((j) => j.userId === repo[0].userId)
            .map((j) => j.subtotal)
            .reduce((x, y) => x + y, 0);
          const totalSale = repo
            .map((j) => j.qty * j.price)
            .reduce((x, y) => x + y, 0);

          const refund = voidSells
            .filter((j) => j.cancelType === 2 && j.userId === repo[0].userId)
            .map((j) => j.subtotal)
            .reduce((x, y) => x + y, 0);

          const itemCancel = cancelItems.filter(
            (j) => repo[0].userId === j.userId
          ).length;
          return {
            ...repo,
            emplName: repo[0].user.firstName + " " + repo[0].user.lastName,
            totalSale: (org && org.curSymbol) + " " + totalSale,
            voidSale: (org && org.curSymbol) + " " + voidSale,
            refund: (org && org.curSymbol) + " " + refund,
            itemCancel: itemCancel,
          };
        })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(EmployeeReport);
