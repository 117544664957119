import { ClearRounded, EditRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { controller, endpoints } from "../../api/actions/api";
import { DropLi } from "../../hooks";
import { useTranslation } from "react-i18next";

const Users = (props) => {
  const { _list, _getById, _clear, _delete, _type } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setLoader, selStore } = useContext(AppContext);
  const { Gender, Role, BoolType } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.User + controller.GetByStore,
        selStore,
        actions.ActionType.List,
        () => setLoader(false)
      );
    }
    return () => {
      _clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  const handleAddEdit = (val) => {
    navigate(`user-form/${val.id || ""}`);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: t("areYouSure"),
      subTitle: t("userDltUser"),
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: t("dltSuccessTitle"),
              subTitle: res.msg,
            });
          } else {
            setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.User, id, actions.ActionType.RmvList, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("edit")}>
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title={t("delete")}>
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          className="error-color"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  const HeaderComp = () => (
    <Button
      onClick={handleAddEdit}
      className="btn-secondary"
      sx={{
        width: { md: "180px", xs: "100%" },
        mb: { xs: "10px", md: "0px" },
        fontSize: "17px",
        padding: "8px",
      }}
    >
      {t("addUser")}
    </Button>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "firstName", label: t("firstName") },
          { id: "lastName", label: t("lastName") },
          { id: "userName", label: t("userName") },
          { id: "dob", label: t("dateOfBirth") },
          { id: "gender", label: t("gender") },
          { id: "phone", label: t("phone") },
          { id: "role", label: t("userRole") },
          { id: "isActive", label: t("activeStatus") },
          { id: "address", label: t("address") },
        ]}
        tblData={
          _type === "users"
            ? _list.map((obj) => {
                return {
                  ...obj,
                  gender: Gender.find((j) => j.id === obj.gender).name,
                  role: Role.find((j) => j.id === obj.role).name,
                  isActive: BoolType.find((j) => j.id === obj.isActive).name,
                };
              })
            : []
        }
      />

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _list: state.pos.list,
  _type: state.pos.type,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(Users);
