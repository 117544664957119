import React, { useContext } from "react";
import { AttachMoneyRounded } from "@mui/icons-material";
import { Button, Grid, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../controls";
import { RandomCode } from "../../hooks/Method";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const Lottery = ({ cartItems, setCartItems }) => {
  const navigate = useNavigate();
  const [val, setVal] = useState("");
  const { t } = useTranslation();
  const { org } = useContext(AppContext);

  const handleInpChange = (e) => {
    setVal(e.target.value);
  };

  const handleCart = () => {
    !cartItems.some((j) => j.name === "Lottery" && j.price === val) &&
    val !== ""
      ? setCartItems((x) => [
          {
            productId: RandomCode(10),
            name: "Lottery",
            price: val,
            tax: 2,
            qty: 1,
            itemType: 2,
          },
          ...x,
        ])
      : setCartItems((x) => [
          ...x.map((j) =>
            j.name === "Lottery" && j.price === val
              ? { ...j, qty: parseInt(j.qty) + 1 }
              : j
          ),
        ]);
  };

  const handleNum = (e, val) => {
    setVal((x) => x + (val !== undefined ? val : e.target.innerText));
  };

  const clear = () => {
    setVal("");
  };

  useEffect(() => {
    return () => {
      setVal("");
    };
  }, []);

  return (
    <>
      {/* Display */}
      <Grid className="tx-center gray-color mb-20 p-5">
        <Input
          value={val}
          onChange={handleInpChange}
          className="bg-white rmv-arrow br-8 w-100pr"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyRounded />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          sx={{
            "& .MuiInputBase-input": {
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "600",
              color: "var(--gray-color)",
            },
          }}
        />
      </Grid>

      <Box
        className="d-flex"
        sx={{
          height: { xs: "calc(100vh - 400px)", md: "calc(100vh - 440px)" },
          flexWrap: { xs: "wrap", md: "inherit" },
        }}
      >
        <Box className="w-100pr d-grid" sx={{ mr: { md: "10px" } }}>
          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={() => handleNum(null, 5)}
              className="w-100pr btn-secondary fs-30 mr-10"
            >
              {org && org.curSymbol} 5
            </Button>
            <Button
              size="large"
              onClick={() => handleNum(null, 10)}
              className="w-100pr btn-secondary fs-30 mr-10"
            >
              {org && org.curSymbol} 10
            </Button>
            <Button
              size="large"
              onClick={() => handleNum(null, 20)}
              className="w-100pr btn-secondary fs-30 mr-10"
            >
              {org && org.curSymbol} 20
            </Button>
            <Button
              size="large"
              onClick={() => handleNum(null, 50)}
              className="w-100pr btn-secondary fs-30"
            >
              {org && org.curSymbol} 50
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              1
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              2
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              3
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30"
            >
              4
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              5
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              6
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              7
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30"
            >
              8
            </Button>
          </Box>

          <Box className="d-flex mb-10">
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              9
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              0
            </Button>
            <Button
              size="large"
              onClick={handleNum}
              className="w-100pr bg-white gray-color fs-30 mr-10"
            >
              .
            </Button>
            <Button
              size="large"
              onClick={clear}
              className="w-100pr bg-white gray-color"
              sx={{ fontSize: { xs: "16px", md: "22px" } }}
            >
              {t("clear")}
            </Button>
          </Box>
        </Box>

        <Box
          className="mb-10"
          sx={{
            width: { xs: "100%", md: "220px" },
            display: { xs: "flex", md: "grid" },
          }}
        >
          <Button
            size="large"
            onClick={() => navigate("/pos")}
            className="bg-white gray-color fs-18"
            sx={{
              mb: { xs: "0px", md: "10px" },
              mr: { xs: "10px", md: "0px" },
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            size="large"
            onClick={handleCart}
            className="w-100pr bg-error white-color fs-26"
          >
            {t("enter")}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Lottery;
