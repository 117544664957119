import React from "react";
import { Route, Routes } from "react-router-dom";
import Forgot from "./forgot/Forgot";
import LoginForm from "./LoginForm";

const Auth = () => {
  return (
    <Routes>
      <Route path="/forgot" element={<Forgot />} />
      <Route index element={<LoginForm />} />
    </Routes>
  );
};

export default Auth;
