import { Menu } from "@mui/material";
import React from "react";

const MenuPopup = (props) => {
  const { id, children, anchorEl, setAnchorEl, ...other } = props;
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id={id}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        className: "bg-white br-6 shadow-sm",
      }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      {...other}
    >
      {children}
    </Menu>
  );
};

export default MenuPopup;
