import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { AppContext } from "../../App";
import { useEffect } from "react";
import { controller, endpoints } from "../../api/actions/api";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProdSellVol = (props) => {
  const { _getById } = props;
  const { setLoader, selStore, org } = useContext(AppContext);
  const [sells, setSells] = useState([]);
  const { t } = useTranslation();

  const onComplete = (res) => {
    if (res.status === 200 && res.result.length > 0) {
      const filteredProds = [];
      const _sells = res.result
        .map((j) =>
          j.sellItems.map((y) => {
            return { ...y, sellDate: j.sellDate };
          })
        )
        .flat();
      const _prods = _sells.filter((j) => j.itemType === 1);
      // Push Lottery Item
      filteredProds.push(_sells.filter((j) => j.itemType === 2));

      // Push Product
      _prods.forEach((j) => {
        var prod = _prods.filter((x) => x.product.id === j.product.id);
        // Check the product list is exist in the list before push to the list.
        !filteredProds.some(
          (x) => JSON.stringify(x) === JSON.stringify(prod)
        ) && filteredProds.push(prod);
      });
      setSells(filteredProds);
      setLoader(false);
    } else setLoader(false);
  };

  useEffect(() => {
    if (selStore) {
      setLoader(true);
      _getById(
        endpoints.Sell + controller.GetSellsByStore,
        selStore,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selStore]);

  return (
    <>
      <Table
        ActionComp={() => <div></div>}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "name", label: t("productName") },
          { id: "itemSold", label: t("numberOfItemSold") },
          { id: "lastMonthSale", label: t("lastMonthSale") },
          { id: "currentMonthSale", label: t("currentMonthSale") },
          { id: "status", label: t("status") },
        ]}
        tblData={
          sells.length > 0
            ? sells
                .filter((j) => j.length > 0)
                .map((prod) => {
                  const lastMonthSale = prod
                    .filter(
                      (j) =>
                        moment(j.sellDate, "MM/DD/yyyy").format("MM/yyyy") ===
                        moment()
                          .subtract(1, "month")
                          .startOf("month")
                          .format("MM/yyyy")
                    )
                    .map((j) => j.qty * j.price)
                    .reduce((x, y) => x + y, 0);

                  const currentMonthSale = prod
                    .filter(
                      (j) =>
                        moment(j.sellDate, "MM/DD/yyyy").format("MM/yyyy") ===
                        moment().format("MM/yyyy")
                    )
                    .map((j) => j.qty * j.price)
                    .reduce((x, y) => x + y, 0);

                  return {
                    ...prod,
                    name:
                      prod[0].product === null
                        ? t("lottery")
                        : prod[0].product.name,
                    itemSold: prod.map((j) => j.qty).reduce((x, y) => x + y, 0),
                    lastMonthSale:
                      (org && org.curSymbol) +
                      " " +
                      lastMonthSale.toLocaleString(),
                    currentMonthSale:
                      (org && org.curSymbol) +
                      " " +
                      currentMonthSale.toLocaleString(),
                    status:
                      currentMonthSale > lastMonthSale ? t("hight") : t("low"),
                  };
                })
            : []
        }
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(ProdSellVol);
