import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Button, Input, InputDate, InputQty, Select } from "../../controls";
import { Alert, Form } from "../../layout";
import { Model, UseForm } from "../../hooks";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { AddRounded, ClearRounded } from "@mui/icons-material";
import * as actions from "../../api/actions/pos";
import { connect } from "react-redux";
import { controller, endpoints } from "../../api/actions/api";
import { useEffect } from "react";
import { RmvEmptyProp } from "../../hooks/Method";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditTransfer = (props) => {
  const { _post, _getById, _getByObj } = props;
  const { t } = useTranslation();
  const { StockTransMdl } = Model();
  const { selStore, stores, org } = useContext(AppContext);
  const { id } = useParams();
  const [barcode, setBarcode] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [findErr, setFindErr] = useState("");
  const { values, errors, setErrors, handleInpChg, handleInpDate } =
    UseForm(StockTransMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [prods, setProds] = useState([]);
  const [fromStore, setFromStore] = useState([]);
  const [toStore, setToStore] = useState([]);

  // Set Store in the dropdown list.
  useEffect(() => {
    setFromStore([
      ...stores
        .filter((j) => j.id !== values.toStoreId)
        .map((j) => {
          return {
            id: j.id,
            label: j.storeName,
          };
        }),
    ]);

    setToStore([
      ...stores
        .filter((j) => j.id !== values.fromStoreId)
        .map((j) => {
          return {
            id: j.id,
            label: j.storeName,
          };
        }),
    ]);

    return () => {
      setFromStore([]);
      setToStore([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, values.fromStoreId, values.toStoreId]);

  useEffect(() => {}, []);

  const validateForm = () => {
    let temp = {};
    temp.date = values.date !== "" ? "" : t("transferDateIsRequired");
    temp.fromStoreId =
      values.fromStoreId !== "" ? "" : t("fromLocationIsRequired");
    temp.toStoreId = values.toStoreId !== "" ? "" : t("toLocationIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const onComplete = (res) => {
        setLoading(false);
        res.status === 201 || res.status === 200
          ? _getById(
              endpoints.StockTrans + controller.GetByStore,
              selStore,
              actions.ActionType.List,
              navigate("/stock-transfer")
            )
          : setAlert({
              isOpen: true,
              type: "error",
              title: t("error"),
              subTitle: res.msg,
            });
      };
      values.items = prods;
      values.storeId = selStore;
      //Convert To FormData
      const formData = new FormData();
      const formVal = RmvEmptyProp(values);
      Object.keys(formVal).forEach((key) =>
        !Array.isArray(formVal[key])
          ? formData.append(key, formVal[key])
          : key === "items"
          ? formVal[key].forEach((val, i) =>
              Object.keys(val).forEach((key1) =>
                formData.append(`${key}[${i}][${key1}]`, val[key1])
              )
            )
          : formVal[key].forEach((val) => formData.append(key, val))
      );
      if (id === "undefined") {
        _post(
          endpoints.StockTrans,
          formData,
          actions.ActionType.Data,
          onComplete
        );
      }
    }
  };

  const handleSearch = (val) => {
    if (val !== "") {
      const onComplete = (res) => {
        const inv = res.result[0];
        if (res.status === 200 && inv && inv.inInventory > 0) {
          !prods.some((j) => j.productId === inv.productId)
            ? setProds([
                {
                  productId: inv.productId,
                  name: inv.product.name,
                  qty: 1,
                  price: inv.product.sellPrice,
                  inInventory: inv.inInventory,
                },
                ...prods,
              ])
            : setProds([
                ...prods.map((j) =>
                  j.productId === inv.productId
                    ? { ...j, qty: parseInt(j.qty) + 1 }
                    : j
                ),
              ]);
        } else if (inv && inv.inInventory <= 0) {
          setAlert({
            isOpen: true,
            type: "error",
            title: t("outOfStock"),
            subTitle: t("outOfStockWarning"),
          });
        } else setFindErr(t("productNotFound"));
        setBarcode("");
      };
      // Get Data From Server
      _getByObj(
        endpoints.Inventory + controller.FindProducts,
        {
          storeId: selStore,
          value: val,
        },
        null,
        onComplete
      );
    }
  };

  const handleQtyChg = (e, prod) => {
    prod.inInventory >= parseInt(e.target.value)
      ? setProds([
          ...prods.map((j) =>
            j.productId === prod.productId
              ? { ...j, qty: Math.abs(e.target.value) }
              : j
          ),
        ])
      : setAlert({
          isOpen: true,
          type: "error",
          title: t("outOfStock"),
          subTitle: t("outOfStockWarning"),
        });
  };

  const handleInc = (prod) => {
    prod.inInventory >= prod.qty + 1
      ? setProds([
          ...prods.map((j) =>
            j.productId === prod.productId
              ? { ...j, qty: parseInt(j.qty) + 1 }
              : j
          ),
        ])
      : setAlert({
          isOpen: true,
          type: "error",
          title: t("outOfStock"),
          subTitle: t("outOfStockWarning"),
        });
  };

  const handleDec = (productId) => {
    setProds([
      ...prods.map((j) =>
        j.productId === productId
          ? { ...j, qty: j.qty > 1 ? parseInt(j.qty) - 1 : j.qty }
          : j
      ),
    ]);
  };

  const handleRmvItem = (prodId) => {
    setProds([...prods.filter((j) => j.productId !== prodId)]);
  };

  return (
    <>
      <Form noValidate>
        <Box className="tx-center p-20">
          <Typography variant="h5" className="fs-26 gray-color fw-5 tx-upp">
            {id === "undefined" ? t("add") : t("update")} {t("stockTransfer")}
          </Typography>
          <div className="bs-bottom mx-w-80 mt-5 m-auto" />
        </Box>

        <div className="pt-20" />
        <Paper className="br-8 shadow-xs">
          <Grid container spacing={2} className="p-10">
            <Grid item xs={12} md={4}>
              <InputDate
                label={t("transferDate")}
                name="date"
                value={values.date}
                error={errors.date}
                onChange={(date) =>
                  handleInpDate("date", new Date(date).toLocaleDateString())
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label={t("locationFrom")}
                name="fromStoreId"
                value={values.fromStoreId}
                error={errors.fromStoreId}
                onChange={handleInpChg}
                fullWidth
                options={fromStore}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label={t("locationTo")}
                name="toStoreId"
                value={values.toStoreId}
                error={errors.toStoreId}
                onChange={handleInpChg}
                fullWidth
                options={toStore}
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Details */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-20">
          {/* Title Bar */}
          <div className="mb-20">
            <span
              className="fs-18 fw-5 gray-color pl-10"
              style={{ borderLeft: "5px double var(--primary-color)" }}
            >
              {t("transferDetails")}
            </span>
          </div>

          {/* Search Box */}
          <Input
            label={t("searchByBarcode")}
            name="barcode"
            value={barcode}
            onChange={(e) => {
              setBarcode(e.target.value);
              setFindErr("");
            }}
            onKeyDown={(e) => e.which === 13 && handleSearch(e.target.value)}
            fullWidth
            error={findErr}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleSearch(barcode)}>
                    <AddRounded />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Product Details */}
          <TableContainer className="mt-20">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("actions")}</TableCell>
                  <TableCell>{t("productName")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell>{t("unitPrice")}</TableCell>
                  <TableCell>{t("subtotal")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prods.map((prod, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Tooltip arrow title={t("delete")}>
                        <IconButton
                          onClick={() => handleRmvItem(prod.productId)}
                          size="small"
                          className="error-color"
                        >
                          <ClearRounded className="fs-14" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{prod.name}</TableCell>
                    <TableCell>
                      <InputQty
                        value={prod.qty}
                        onIncrease={() => handleInc(prod)}
                        onDecrease={() => handleDec(prod.productId)}
                        onChange={(e) => handleQtyChg(e, prod)}
                      />
                    </TableCell>
                    <TableCell>
                      {(org && org.curSymbol) + " " + prod.price}
                    </TableCell>
                    <TableCell>
                      {(org && org.curSymbol) +
                        " " +
                        (prod.qty * prod.price).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box className="tx-right p-10">
              <Typography variant="h6">
                {t("total")}: {(org && org.curSymbol) + " "}
                {prods.length > 0
                  ? prods
                      .map((j) => j.qty * j.price)
                      .reduce((x, y) => x + y, 0)
                      .toFixed(2)
                  : 0}
              </Typography>
            </Box>
          </TableContainer>
        </Paper>

        {/* Note */}
        <div className="pt-20" />
        <Paper className="br-8 shadow-xs p-20">
          <Input
            label={t("note")}
            multiline
            rows={3}
            name="note"
            value={values.note}
            error={errors.note}
            onChange={handleInpChg}
            fullWidth
          />
        </Paper>

        <Grid item xs={12} className="tx-right mt-20">
          <Button
            loading={loading}
            onClick={handleSubmit}
            sx={{ width: { md: "150px", xs: "100%" } }}
            size="large"
          >
            {id === "undefined" ? t("submit") : t("saveChanges")}
          </Button>
        </Grid>
      </Form>
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _stores: state.pos.stores,
  _store: state.pos.defStore,
});

const mapActionToProps = {
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
  _getByObj: actions.getByObj,
  _get: actions.get,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditTransfer);
