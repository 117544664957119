import { createTheme } from "@mui/material/styles";

const prop = {
  primary: "#007bff",
  dark: "#3d4e5f",
  success: "#00E396",
  error: "#ff0000",
  warning: "#FFCC00",
  info: "#00e1ff",
  white: "#fff",
  whitemd: "#D1D5DB",
  black: "#000",
  gray: "#7a7a7a",
};

// Create a theme instance.
const theme = createTheme({
  status: {
    danger: prop.danger,
  },
  palette: {
    default: {
      contrastText: prop.black,
    },
    primary: {
      main: prop.primary,
      contrastText: prop.white,
    },
    secondary: {
      main: prop.dark,
      contrastText: prop.white,
    },
    white: {
      main: prop.white,
      contrastText: prop.gray,
    },
    whitemd: {
      main: prop.whitemd,
      contrastText: prop.gray,
    },
    error: {
      main: prop.error,
      contrastText: prop.white,
    },
    warning: {
      main: prop.warning,
      contrastText: prop.white,
    },
    info: {
      main: prop.info,
      contrastText: prop.white,
    },
    success: {
      main: prop.success,
      contrastText: prop.white,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "var(--shadow-xs)",
          border: "1px solid var(--primaryMD-color)",
          backgroundColor: "var(--whiteMD-color)",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "var(--white-color)",
          color: "var(--primary-color)",
          backdropFilter: "blur(15px)",
          fontSize: "12px",
          textTransform: "uppercase",
        },
        arrow: {
          color: "var(--white-color)",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          transition: "0.2s",
          "&:hover": {
            color: "var(--primaryDark-color)",
            backgroundColor: "var(--primaryXS-color)",
            ".MuiListItemIcon-root": {
              color: "var(--primary-color)",
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          color: "var(--dark-color)",
          boxShadow: "none",
          backdropFilter: "0",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0.8rem",
          backgroundColor: "var(--white-color)",
          fieldset: {
            border: "1px solid var(--graySM-color)",
          },
          "&:hover fieldset": {
            borderColor: `${prop.primary} !important`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.9rem",
          fontWeight: "500",
          letterSpacing: "0.8px",
          ":disabled": {
            backgroundColor: "var(--primarySM-color)",
            color: "var(--primary-color)",
          },
        },
        endIcon: {
          marginLeft: "3px",
        },
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "var(--primarySM-color)",
            color: "var(--primary-color)",
            "&:hover": {
              backgroundColor: "var(--primaryMD-color)",
            },
          },
        },
      ],
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&, &:hover, &.MuiFilledInput-root.Mui-focused": {
            backgroundColor: "var(--white-color)",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          },
          "&.Mui-disabled": {
            backgroundColor: "var(--primaryXS-color)",
          },
        },
      },
    },
  },
});

export default theme;
