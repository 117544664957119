import { ThemeProvider } from "@emotion/react";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Auth, DashRoute, CLoader } from "./component";
import { connect } from "react-redux";
import * as actions from "./api/actions/pos";
import { controller, endpoints } from "./api/actions/api";
import { Loader } from "./controls";
import { IsEmptyObj } from "./hooks/Method";
import { Currencies } from "./hooks/Currencies";
import theme from "./utils/theme";
import "./scss/App.scss";
import "./scss/JHProg.scss";
import { useTranslation } from "react-i18next";

export const AppContext = createContext({});

function App(props) {
  const { _get, _authUser } = props;
  const { i18n } = useTranslation();

  const [loader, setLoader] = useState(false);
  const [cLoader, setCLoader] = useState(false);
  const [selStore, setSelStore] = useState(0);
  const [stores, setStores] = useState([]);
  const [org, setOrg] = useState({});

  useEffect(() => {
    setCLoader(true);
    const onComplete = () => setCLoader(false);
    _get(
      endpoints.Auth + controller.GetAuthUser,
      actions.ActionType.Auth,
      onComplete
    );
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_authUser.id) {
      // Set Currency
      var currency = Currencies.find((j) => j.id === _authUser.org.currency);
      setOrg({
        ..._authUser.org,
        curSymbol: currency && currency.symbol_native,
      });

      // Set Language
      i18n.changeLanguage(_authUser.language || "en");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_authUser]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          loader,
          setLoader,
          cLoader,
          setCLoader,
          stores,
          setStores,
          selStore,
          setSelStore,
          org,
          setOrg,
        }}
      >
        <Loader show={loader} />
        <CLoader show={cLoader} />
        <Router>
          <Routes>
            <Route
              path="/*"
              element={!IsEmptyObj(_authUser) ? <DashRoute /> : <Auth />}
            />
          </Routes>
        </Router>
      </AppContext.Provider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
});

const mapActiontoProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActiontoProps)(App);
