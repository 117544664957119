import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Barcode from "react-barcode/lib/react-barcode";
import { PrintDiv } from "../../hooks/Method";
import { useTranslation } from "react-i18next";

function BarcodeGen(props) {
  const { value, setShowPopup } = props;
  const { t } = useTranslation();

  const handlePrint = () => {
    PrintDiv("barcodeCanva");
  };

  return (
    <Box className="p-15">
      <Box
        className="tx-center mb-20"
        id="barcodeCanva"
        sx={{ overflow: "auto" }}
      >
        <Barcode value={value} />
      </Box>
      <Box
        className="w-100pr d-flex"
        sx={{ flexWrap: { md: "inherit", xs: "wrap" } }}
      >
        <Button
          onClick={handlePrint}
          type="submit"
          fullWidth
          className="w-100pr"
        >
          {t("print")}
        </Button>
        <Button
          onClick={() => setShowPopup(false)}
          className="w-100pr error-color bg-errorSM"
          sx={{ ml: { md: "10px" }, mt: { xs: "15px", md: "0px" } }}
        >
          {t("close")}
        </Button>
      </Box>
    </Box>
  );
}

export default BarcodeGen;
