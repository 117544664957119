import React from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import moment from "moment/moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const StoreExpenses = ({ expenses }) => {
  const { t } = useTranslation();

  const [sortedExp, setSortedExp] = useState([]);
  useEffect(() => {
    const filteredExp = [];
    const _exps = expenses.filter(
      (j) =>
        moment(j.addDate, "MM/DD/yyyy").format("MM/yyyy") ===
        moment().format("MM/yyyy")
    );
    _exps.forEach((j) => {
      var exp = _exps.filter((x) => x.expenseCatId === j.expenseCatId);
      !filteredExp.some((x) => JSON.stringify(x) === JSON.stringify(exp)) &&
        filteredExp.push(exp);
    });
    setSortedExp(filteredExp);
  }, [expenses]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: sortedExp.map((j) => j[0].expenseCat.name),
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontWeight: "600",
      fontSize: "15px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const series = sortedExp.map((j) =>
    j.map((x) => Math.round(x.amount)).reduce((x, y) => x + y, 0)
  );

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-20">
        {t("storeExpenses")}
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

export default StoreExpenses;
