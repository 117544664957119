import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const SalesByEmployee = ({ sellsItem }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [sellsByUser, setSellsByUser] = useState([]);
  useEffect(() => {
    const filteredLi = [];
    const _sells = sellsItem.filter(
      (j) =>
        moment(j.sellDate, "MM/DD/yyyy").format("MM/yyyy") ===
        moment().format("MM/yyyy")
    );
    _sells.forEach((j) => {
      var prod = _sells.filter((x) => x.userId === j.userId);
      // Check the product list is exist in the list before push to the list.
      !filteredLi.some((x) => JSON.stringify(x) === JSON.stringify(prod)) &&
        filteredLi.push(prod);
    });
    setSellsByUser(filteredLi);
  }, [sellsItem]);

  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    labels: sellsByUser.map(
      (j) => j[0].user.firstName + " " + j[0].user.lastName
    ),
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return (
          val +
          " - " +
          (org && org.curSymbol) +
          opts.w.globals.series[opts.seriesIndex]
        );
      },
      show: true,
      position: "bottom",
      horizontalAlign: "bottom",
      fontSize: "15px",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
        },
      },
    ],
  };

  const series = sellsByUser.map((j) =>
    j.map((j) => Math.round(j.qty * j.price)).reduce((x, y) => x + y, 0)
  );

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color mb-20">
        {t("salesByEmployees")}
      </Typography>
      <Box>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={330}
        />
      </Box>
    </Paper>
  );
};

export default SalesByEmployee;
