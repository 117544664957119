import { VisibilityRounded } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dialog, Table } from "../../layout";
import * as actions from "../../api/actions/pos";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../App";
import { endpoints } from "../../api/actions/api";
import ViewPurch from "../purchase/ViewPurch";
import { useTranslation } from "react-i18next";

const PurchaseReport = (props) => {
  const { _get } = props;
  const { t } = useTranslation();
  const [showForm, setShowForm] = useState(false);
  const { setLoader, org } = useContext(AppContext);
  const [purchases, setPurchases] = useState([]);
  const [viewData, setViewData] = useState();

  const handleView = (data) => {
    setViewData(data);
    setShowForm(true);
  };

  const onComplete = (res) => {
    setLoader(false);
    res.status === 200 && res.result.length > 0 && setPurchases(res.result);
  };

  useEffect(() => {
    setLoader(true);
    _get(endpoints.Purchase, null, onComplete);
    return () => {
      setPurchases([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActionComp = (props) => (
    <Box>
      <Tooltip arrow title={t("View")}>
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          className="btn-primary mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={() => <div></div>}
        tblHead={[
          { id: "actions", label: t("actions") },
          { id: "purchDate", label: t("purchaseDate") },
          { id: "purchBy", label: t("purchaseBy") },
          { id: "totalItem", label: t("totalItem") },
          { id: "totalPrice", label: t("totalPrice") },
          { id: "supplier", label: t("supplierName") },
        ]}
        tblData={purchases.map((purch) => {
          return {
            ...purch,
            purchBy: purch.user.firstName + " " + purch.user.lastName,
            totalItem: purch.purchItems
              .map((j) => j.qty)
              .reduce((x, y) => x + y, 0),
            totalPrice:
              (org && org.curSymbol) +
              " " +
              purch.purchItems
                .map((j) => j.qty * j.purchPrice)
                .reduce((x, y) => x + y, 0),
            supplier: purch.supplier.company,
          };
        })}
      />

      {/* Edit Dialog */}
      {viewData && (
        <Dialog show={showForm} onClose={false} maxWidth="lg">
          <ViewPurch {...{ setShowForm, viewData }} />
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapActionToProps = {
  _get: actions.get,
};

export default connect(mapStateToProps, mapActionToProps)(PurchaseReport);
