import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../App";

const TopTenItems = ({ products }) => {
  const { t } = useTranslation();
  const { org } = useContext(AppContext);
  const [prods, setProds] = useState([]);
  useEffect(() => {
    setProds(
      products
        .sort(
          (f, s) =>
            s
              .map((j) => j.qty * (j.product.sellPrice - j.product.purchPrice))
              .reduce((x, y) => x + y, 0) -
            f
              .map((j) => j.qty * (j.product.sellPrice - j.product.purchPrice))
              .reduce((x, y) => x + y, 0)
        )
        .slice(0, 10)
    );
  }, [products]);

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    dataLabels: {
      formatter: (val) => `${(org && org.curSymbol) + " " + val}`,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: prods.map((j) => j[0].product.name),
      labels: {
        formatter: (val) => `${(org && org.curSymbol) + " " + val}`,
        style: {
          fontSize: "14px",
        },
      },
    },
    colors: ["#00E396"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => `${(org && org.curSymbol) + " " + val}`,
      },
    },
  };
  const series = [
    {
      name: t("profit"),
      data: prods.map((j) =>
        j
          .map((j) =>
            Math.round(j.qty * (j.product.sellPrice - j.product.purchPrice))
          )
          .reduce((x, y) => x + y, 0)
      ),
    },
  ];

  return (
    <Paper className="p-15">
      {/* Chart */}
      <Typography varint="h6" className="fs-18 fw-5 gray-color">
        {t("top10ProfitMakingItems")}
      </Typography>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="300"
      />
    </Paper>
  );
};

export default TopTenItems;
