import { Grid, Paper, Divider } from "@mui/material";
import React, { useContext } from "react";
import { Button, Input, InputDate, InputImg, Select } from "../../controls";
import { Alert, Form, Tabs } from "../../layout";
import { DropLi, Model, UseForm } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { ToFormData, RmvEmptyProp, MaxLength } from "../../hooks/Method";
import { useEffect } from "react";
import { useState } from "react";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditStore = (props) => {
  const { _post, _put, _getById } = props;
  const { t } = useTranslation();
  const { StoreMdl } = Model();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { Gender } = DropLi();
  const { setStores } = useContext(AppContext);
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    handleInpDate,
    handleFile,
  } = UseForm(StoreMdl);
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    if (id) {
      _getById(
        endpoints.Store + controller.GetById,
        id,
        null,
        (res) =>
          res.status === 200 &&
          setValues({ ...values, ...RmvEmptyProp(res.result) })
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const validateForm = () => {
    let temp = {};
    temp.storeName = values.storeName !== "" ? "" : t("storeNameIsRequired");
    temp.phone = values.phone !== "" ? "" : t("phoneIsRequired");
    temp.tax = values.tax !== "" ? "" : t("taxPercentageIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const onComplete = (res) => {
      setLoading(false);
      if (res) {
        if (res.status === 201 || res.status === 200) {
          setAlert({
            isOpen: true,
            type: "success",
            title: t("success"),
            subTitle: `${t("store")} ${id ? t("updated") : t("added")} ${t(
              "successfully"
            )}`,
            onClick: () => navigate("/stores"),
          });
          id
            ? setStores((x) => [
                ...x.map((j) =>
                  j.id === res.result.id
                    ? { ...res.result, label: res.result.storeName }
                    : j
                ),
              ])
            : setStores((x) => [
                { ...res.result, label: res.result.storeName },
                ...x,
              ]);
        } else
          setAlert({
            isOpen: true,
            type: "error",
            title: t("error"),
            subTitle: res.msg,
          });
      }
    };
    if (validateForm()) {
      setLoading(true);
      if (!id) {
        _post(
          endpoints.Store,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      } else if (id !== 0) {
        _put(
          endpoints.Store,
          ToFormData(RmvEmptyProp(values)),
          actions.ActionType.Data,
          onComplete
        );
      }
    }
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Paper>
          <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
            <div>
              <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
                {id ? t("update") : t("add")} {t("store")}
              </h1>
            </div>

            <div>
              <Button
                type="submit"
                variant="text"
                size="large"
                loading={loading}
              >
                {id ? t("saveChanges") : t("submit")}
              </Button>
            </div>
          </div>
          <Divider />
          <div className="pl-15 pr-15 pb-15">
            <Tabs
              className="mt-30"
              header={[
                { label: t("storeDetails"), icon: "" },
                { label: t("managerDetails"), icon: "" },
              ]}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Input
                    label={t("storeName")}
                    name="storeName"
                    required={true}
                    value={values.storeName}
                    error={errors.storeName}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("phone")}
                    name="phone"
                    required={true}
                    value={values.phone}
                    error={errors.phone}
                    onChange={handleInpChg}
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 15))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("taxPercentage")}
                    name="tax"
                    required={true}
                    value={values.tax}
                    error={errors.tax}
                    onChange={handleInpChg}
                    onInput={(e) =>
                      (e.target.value = MaxLength(e.target.value, 12))
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label={t("address")}
                    name="address"
                    value={values.address}
                    error={errors.address}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("city")}
                    name="city"
                    value={values.city}
                    error={errors.city}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("state")}
                    name="state"
                    value={values.state}
                    error={errors.state}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("ZIP")}
                    name="zip"
                    value={values.zip}
                    error={errors.zip}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputImg
                    label={t("logo")}
                    name="logoFile"
                    src={values.logo}
                    value={values.logoFile}
                    onChange={handleFile}
                    error={errors.logoFile}
                    rmv={() => {
                      setValues({ ...values, logoFile: "", logo: "" });
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("firstName")}
                    name="firstName"
                    value={values.firstName}
                    error={errors.firstName}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("lastName")}
                    name="lastName"
                    value={values.lastName}
                    error={errors.lastName}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputDate
                    label={t("dateOfBirth")}
                    name="dob"
                    required={true}
                    value={values.dob}
                    error={errors.dob}
                    maxDate={new Date()}
                    onChange={(date) =>
                      handleInpDate("dob", new Date(date).toLocaleDateString())
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    label={t("gender")}
                    name="gender"
                    fullWidth
                    value={values.gender}
                    error={errors.gender}
                    onChange={handleInpChg}
                    options={Gender}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("phone")}
                    name="mngPhone"
                    value={values.mngPhone}
                    error={errors.mngPhone}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input
                    label={t("SSN")}
                    name="ssn"
                    value={values.ssn}
                    error={errors.ssn}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    label={t("address")}
                    name="mngAddress"
                    value={values.mngAddress}
                    error={errors.mngAddress}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("city")}
                    name="mngCity"
                    value={values.mngCity}
                    error={errors.mngCity}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("state")}
                    name="mngState"
                    value={values.mngState}
                    error={errors.mngState}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Input
                    label={t("ZIP")}
                    name="mngZip"
                    value={values.mngZip}
                    error={errors.mngZip}
                    onChange={handleInpChg}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Tabs>
          </div>
        </Paper>
      </Form>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
});

const mapActionToProps = {
  _get: actions.get,
  _post: actions.post,
  _put: actions.put,
  _getById: actions.getById,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditStore);
