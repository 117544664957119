import {
  AutoGraphRounded,
  SsidChartRounded,
  StackedLineChartRounded,
  WaterfallChartRounded,
} from "@mui/icons-material";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const PeriodicalSalesReports = (props) => {
  const { yearlySells, montlySells, weeklySells, dailySells } = props;
  const { t } = useTranslation();

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            title={t("yearlySales")}
            value={yearlySells}
            icon={<SsidChartRounded className="fs-50 primary-color" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            title={t("monthlySales")}
            value={montlySells}
            icon={<StackedLineChartRounded className="fs-50 primary-color" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            title={t("weeklySales")}
            value={weeklySells}
            icon={<WaterfallChartRounded className="fs-50 primary-color" />}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Widget
            isBorder={false}
            title={t("dailySales")}
            value={dailySells}
            icon={<AutoGraphRounded className="fs-50 primary-color" />}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  _authUser: state.pos.authUser,
  _defStore: state.pos.defStore,
});

const mapActiontoProps = {};

export default connect(
  mapStateToProps,
  mapActiontoProps
)(PeriodicalSalesReports);

const Widget = (props) => {
  const { title, value, icon, isBorder = true } = props;
  const { org } = useContext(AppContext);
  return (
    <Box
      className="d-flex a-center j-between p-40"
      sx={
        isBorder
          ? {
              borderRight: { md: "1px solid var(--graySM-color)" },
              borderBottom: {
                xs: "1px solid var(--graySM-color)",
                md: "none",
              },
            }
          : {}
      }
    >
      <div className="d-grid">
        <span className="fs-16 fw-5 gray-color mb-20">{title}</span>
        <div>
          <span className="fs-16">{org && org.curSymbol} </span>
          <span className=" fw-5 fs-26">{value}</span>
        </div>
      </div>
      <div>{icon}</div>
    </Box>
  );
};
