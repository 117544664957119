import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Button, Input, LabelComp } from "../../controls";
import { Model, UseForm } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../api/actions/pos";
import { controller, endpoints } from "../../api/actions/api";
import { IsEmptyObj, MaxLength, ToFormData } from "../../hooks/Method";
import { useState } from "react";
import { useEffect } from "react";
import { Snackbar } from "../../layout";
import { AppContext } from "../../App";
import { useTranslation } from "react-i18next";

const AddEditInventory = (props) => {
  const { _post, _type, _data, _getById, _clear } = props;
  const { t } = useTranslation();
  const { InventoryMdl } = Model();
  const navigate = useNavigate();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInpChg,
    resetFormControls,
  } = UseForm(InventoryMdl);
  const { selStore } = useContext(AppContext);
  const [prod, setProd] = useState({});
  const [barcode, setBarcode] = useState("");
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    type: "success",
    msg: "",
    show: false,
  });

  const validateForm = () => {
    let temp = {};
    temp.newInventory =
      values.newInventory !== "" ? "" : t("newStockIsRequired");

    setErrors({ ...temp });
    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (isSaveExit = false) => {
    const onComplete = () => {
      if (isSaveExit) {
        _getById(
          endpoints.Inventory + controller.GetProdsByStore,
          selStore,
          actions.ActionType.List,
          navigate("/inventory")
        );
      } else {
        setProd({});
        setBarcode("");
        setErr();
        resetFormControls();
        setSnackbar((x) => ({
          ...x,
          show: true,
          msg: t("invAddedTitle"),
        }));
      }
      setLoading(false);
    };

    if (validateForm() && prod.id !== undefined) {
      setLoading(true);
      setErr("");
      _post(endpoints.Inventory, ToFormData(values), null, onComplete);
    } else if (prod.id === undefined) setErr(t("invProdError"));
  };

  const handleProdFind = (e) => {
    var val = e.target.value;
    setBarcode(val);
    const onComplete = (res) => {
      if (res.status === 400) setErr(t("productNotFound"));
    };
    if (val !== "" && e.which === 13) {
      _getById(
        endpoints.Product + controller.GetProductByBarcode,
        val,
        actions.ActionType.Data,
        onComplete
      );
    } else setErr();
  };

  useEffect(() => {
    return () => {
      resetFormControls();
      setProd({});
      setBarcode("");
      setErr();
      _clear({}, actions.ActionType.ClearData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_type === "product") {
      setProd({ ..._data, inInventory: 0 });
      setValues({
        ...values,
        productId: _data.id,
        storeId: selStore,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data]);

  useEffect(() => {
    if (!IsEmptyObj(_data) && _data.id !== undefined) {
      const onComplete = (res) => {
        res.status === 200 &&
          setProd((x) => ({ ...x, inInventory: res.result.inInventory }));
      };

      _getById(
        endpoints.Inventory + controller.GetById,
        _data.id,
        null,
        onComplete
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_data]);

  return (
    <>
      <Paper className="mt-30 shadow-xs br-8">
        <div className="mb-10 d-flex j-between a-center pt-10 pl-10 pr-10">
          <div>
            <h1 className="form-title ml-10 fw-4 gray-color tx-upp border-left">
              {t("addInventory")}
            </h1>
          </div>

          <div>
            <Button
              loading={loading}
              onClick={() => handleSubmit(true)}
              variant="text"
              sx={{
                minWidth: { md: "150px", xs: "100%" },
                mr: { md: "10px", xs: "0px" },
                mb: { xs: "10px", md: "0px" },
              }}
              className="pr-10 pl-10"
            >
              {t("saveAndExit")}
            </Button>
            <Button
              loading={loading}
              onClick={() => handleSubmit(false)}
              variant="text"
              sx={{ minWidth: { md: "230px", xs: "100%" } }}
            >
              {t("saveAndAddAnother")}
            </Button>
          </div>
        </div>
        <Divider />
        <Grid container spacing={3} className="p-20 pt-0">
          {err && (
            <Grid item xs={12}>
              <Typography color="error">
                <span className="tx-upp fw-6">{t("error")} :</span> {err}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Input
              label={t("searchByBarcode")}
              onChange={(e) => setBarcode(e.target.value)}
              value={barcode}
              onKeyDown={handleProdFind}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label={t("newStock")}
              name="inInventory"
              className="rmv-arrow"
              value={values.inInventory}
              error={errors.inInventory}
              type="number"
              onInput={(e) => (e.target.value = MaxLength(e.target.value, 10))}
              onChange={handleInpChg}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <LabelComp label={t("productId")} value={prod.id} />
          </Grid>
          <Grid item xs={12} md={10}>
            <LabelComp label={t("productName")} value={prod.name} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelComp label={t("inStock")} value={prod.inInventory} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelComp label={t("purchasePrice")} value={prod.purchPrice} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelComp label={t("sellPrice")} value={prod.sellPrice} />
          </Grid>
          <Grid item xs={12}>
            <LabelComp label={t("productDetails")} value={prod.descr} />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _data: state.pos.data,
  _type: state.pos.type,
  _store: state.pos.defStore,
  _authUser: state.pos.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _post: actions.post,
  _clear: actions.clear,
};

export default connect(mapStateToProps, mapActionToProps)(AddEditInventory);
